import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { 
  phq9AlertChild,
  depressionAlert,
  crafftAlert,
  sbirtAlerts,
  phq9AlertGuardian,
  catSSAlert,
  getCmrsAlert,
  gadAlert
 } from './alertBlockHelpers'


const AlertBlock = ({ advanceReport }) => {
  const phq9Child = phq9AlertChild(advanceReport)
  const phq9Guardian = phq9AlertGuardian(advanceReport)
  const depression = depressionAlert(advanceReport)
  const crafft = crafftAlert(advanceReport)
  const sbirt = sbirtAlerts(advanceReport)
  const catSS = catSSAlert(advanceReport)
  const maniaChild = getCmrsAlert(advanceReport, 'child')
  const maniaGuardian = getCmrsAlert(advanceReport, 'guardian')
  const gad = gadAlert(advanceReport)

  // If there are no severe outcomes, we want to just not show this entire block
  const allOfThem = phq9Child +
                    phq9Guardian +
                    depression +
                    crafft +
                    sbirt.join('') +
                    catSS +
                    maniaChild +
                    maniaGuardian +
                    gad

  if (allOfThem === '') return null
  
  return (
    <div className="alert-block container">
      <div className="bg-sw-light-red rounded w-full">
        <div className="p-8 flex items-center">
          <div className="flex justify-center mr-4 text-5xl text-sw-primary-red">
            <FontAwesomeIcon icon={faExclamationTriangle} className="text-5xl text-sw-primary-red" />
          </div>
          <div className="text-sw-primary-red">
            <div className="text-base font-bold leading-loose">
              There IS an urgent concern today:
            </div>
            <div className="text-sm leading-loose">
              <div>{phq9Child}</div>
              <div>{phq9Guardian}</div>
              <div>{depression}</div>
              <div>{crafft}</div>
              <div>{sbirt}</div>
              <div>{catSS}</div>
              <div>{maniaChild}</div>
              <div>{maniaGuardian}</div>
              <div>{gad}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
export default AlertBlock