import React from 'react'
import PrintReportMenu from './PrintReportMenu'
import { url } from '../../../common/Variables'

const ReportNavHeaders = ({ selectedIdx, onSectionClick, printClass }) => {
  const headers = [
    {text: 'SUMMARY', linkTo: '#summary'},
    {text: 'READINESS, PREFERENCE, BARRIERS', linkTo: '#readiness-preferences-barriers'},
    {text: 'ITEM LEVEL REPORT', linkTo: '#item-level-report'},
    {text: 'CLINICAL CARE RESOURCES', linkTo: '#clinical-care-resources'},
    {text: 'PATIENT HANDOUT', linkTo: '#patient-handout'}
  ]

  return headers.map((header, i) => {
    const selectedClass = selectedIdx === i ? "text-sw-primary-blue underline" : "text-sw-light-gray"
    if (printClass === '') {
      return (
        <nav key={header.text}>
          <div
            className={`nav-headers font-bold text-xs mr-2 ${selectedClass}`}
            onClick={() => onSectionClick(i)}
          >
            {header.text}
          </div>
        </nav>
      )
    }

    return (
      <nav key={header.text} className="w-1/5">
        <a
          className="nav-headers font-bold text-xs mr-2 text-sw-light-gray"
          onClick={() => onSectionClick(i)}
          href={header.linkTo}
        >
          {header.text}
        </a>
      </nav>
    )
  })
}

const StickyHeader = ({
  firstName,
  lastName,
  birthday,
  lastAnswerDate,
  selectedIdx,
  onSectionClick,
  onItemClick,
  printClass
}) => {

  var reportDate = "N/A"
  if (lastAnswerDate) reportDate = new Date(lastAnswerDate).toDateString()
  
  return (
    <header className="sticky-header border-b-2 filter drop-shadow-md bg-white pb-1 fixed w-screen top-0 z-10">
      <nav>
        <div className="bg-sw-light-blue sm:py-2">
          <div className="container items-center flex justify-between">
            <div className="text-xs font-bold">
              <a href={`${url}/participants`} target="_blank" rel="noopener noreferrer">
                PARTICIPANTS
              </a>
              &nbsp;&gt; PATIENT REPORT
            </div>
            <div className="text-3xl font-bold text-sw-light-gray">
              CONFIDENTIAL
            </div>
          </div>
        </div>
      </nav>
      <div className="container">
        <div className="flex items-center justify-between my-4 sm:my-6">
          <div>
            <div className="text-xs font-bold">
              PATIENT INFORMATION
            </div>
            <h1 className="flex flex-wrap text-xl text-dark-blue font-bold leading-relaxed">
              <span className="">
                {firstName}, {lastName}&nbsp; | &nbsp;
              </span>
              <span className="mr-2">
                DOB: {birthday}
              </span>
            </h1>
            <div className="text-xs">
              Survey Date: {reportDate}
            </div>
          </div>
          <PrintReportMenu onItemClick={onItemClick} />
        </div>
        <div className="report-nav-headers flex justify-between">
          <ReportNavHeaders
            selectedIdx={selectedIdx}
            onSectionClick={onSectionClick}
            printClass={printClass}
          />
        </div>
      </div>
    </header>
  )
}
export default StickyHeader;
