import React from 'react';
import Counseling from './Counseling';
import ClinicalCareResources from './ClinicalCareResources'
import PatientHandout from './PatientHandout';
import ClinicalCareHeader from '../AdvancedReport/NavHeaders/ClinicalCareHeader'
import PatientHandoutHeader from '../AdvancedReport/NavHeaders/PatientHandoutHeader'

const Handouts = () => {
  return (
    <div className="handouts">
      <ClinicalCareHeader />
      <ClinicalCareResources />
      <Counseling />
      <PatientHandoutHeader />
      <PatientHandout />
    </div>
  )
}

export default Handouts;