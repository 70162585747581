import React from 'react';
import DetailsQuestionTable from '../DetailsQuestionTable'
import AlertCard from '../../AlertCard'

const crafftScore = (reportDetails) => {
  const { crafft } = reportDetails.results.child
  if (crafft && crafft !== "No answer given") {
    return crafft.charAt(crafft.length - 1)
  }
  
  return "No Score"
}

const ChildCrafft = ({ reportDetails, crafftDetails }) => {
  const crafftTotal = crafftScore(reportDetails)
  const crafftIsPositive = crafftTotal !== 'No Score' && crafftTotal !== '0' && crafftTotal !== '1'
  
  var crafftColor = crafftIsPositive ? 'red' : 'green'
  if (crafftTotal === 'No Score') crafftColor = 'grey'

  const crafftText = crafftIsPositive ? 'Concern' : 'No Concern'
  const crafftExtraText = `Total Score: ${crafftTotal}/6`

  return (
    <div className="mx-auto mb-8">
      <p className="text-xs font-bold text-center pt-8 border-t-2 border-sw-lightest-gray">
        ADOLESCENT REPORT
      </p>
      <p className="text-xl text-center font-bold text-sw-dark-blue pt-2">
        Adolescent CRAFFT Score
      </p>
      <div className="w-full mx-auto flex justify-center">
        <div className="w-3/4 sm:w-1/3 flex justify-center mt-4 mb-8">
          <AlertCard
            color={crafftColor}
            text={crafftText}
            extraText={crafftExtraText}
          />
        </div>
      </div>
      <DetailsQuestionTable questionType={crafftDetails} />
    </div>
  )
}

export default ChildCrafft
