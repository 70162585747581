import React from 'react';
import '../assets/scss/_common.scss';
import BulletsComponent from './OverlayComponents/BulletsComponent';
import DefaultComponent from './OverlayComponents/DefaultComponent';
import DisclaimerComponent from './OverlayComponents/DisclaimerComponent';
import ExitComponent from './OverlayComponents/ExitComponent';
import LoginComponent from './OverlayComponents/LoginComponent';
import MemeComponent from './OverlayComponents/MemeComponent';

const MEME_MAP = {
  'meme2': '',
  'meme3': '3',
  'meme4': '4'
}

const buttons = (props) => {
  if (props.disclaimer) {
    return (
      <div className="overlay-button-container">
        <button
          className="overlay-button"
          onClick={() => props.returnToQuestion()}
        >
          <p>
            Ok, continue to survey
          </p>
        </button>
      </div>
    );
  } if (props.exit) {
    return (
      <div className="overlay-button-container">
        <button
          className="overlay-button"
          onClick={() => props.returnToQuestion()}
        >
          <p>
            No, go back
          </p>
        </button>
        <button
          className="overlay-button border-left"
          onClick={() => props.exitAndSave()}
        >
          <p>
            Yes, exit and save
          </p>
        </button>
      </div>
    );
  } return (
    <div className="overlay-button-container">
      <button
        className="overlay-button"
        onClick={() => props.returnToQuestion()}
      >
        <p>
          Ok, continue to survey
        </p>
      </button>
    </div>
  );
};

const Overlay = (props) => {
  const {
    modalVisible, memes, memeInfo, bullets, disclaimer, exit, login, title, question, detail
  } = props;
  if (!modalVisible || (memes && !memeInfo)) return null;

  if (memes) {
    return (
      <MemeComponent
        image={MEME_MAP[memeInfo]}
        returnToQuestion={() => props.returnToQuestion()}
      />
    )
  }

  if (bullets) return <BulletsComponent question={question} button={buttons(props)} />;

  if (disclaimer) return <DisclaimerComponent question={question} button={buttons(props)} />;

  if (exit) return <ExitComponent title={title} question={question} button={buttons(props)} />;

  if (login) {
    return <LoginComponent title={title} question={question} detail={detail} button={buttons(props)} />;
  }

  return <DefaultComponent title={title} question={question} button={buttons(props)} />;
};

export default Overlay;
