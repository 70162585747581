export const answer = (instance) => {
  const answers = instance.map((item) => (
    {
      value: item.id,
      label: item.text
    }
  ));
  return answers;
};

export const mapQuestion = (data) => ({
  instanceId: data.id,
  questionId: data.current_question.id ? data.current_question.id : '',
  question: data.current_question.text ? data.current_question.text : '',
  answers: answer(data.current_question.answers) ? answer(data.current_question.answers) : '',
  skippable: data.current_question.skippable ? data.current_question.skippable : '',
  instructions: data.current_question.instructions,
  researchInstructions: data.current_question.researcher_instructions ? data.current_question.researcher_instructions : '',
  progress: data.progress,
  status: data.status,
  openField: data.current_question.id === 5 ? data.current_question.openField : null,
  type: data.current_question.answer_type,
  subject: data.subject_type
});

export const mapComplete = (data) => ({
  instanceId: data.id,
  progress: data.progress,
  status: data.status,
  instructions: '',
  researchInstructions: ''
});

export const mapParticipants = (dates) => {
  const participants = dates[Object.keys(dates)[0]].map((item) => (
    {
      instanceId: item.id,
      patientId: item.participant_id,
      childId: item.child_survey_instance.id,
      guardianId: item.guardian_survey_instance.id,
      lastName: item.last_name,
      firstName: item.first_name,
      scheduledTo: item.scheduled_to,
      birthday: item.dob,
      status: {
        childStatus: item.child_survey_instance.status,
        guardianStatus: item.guardian_survey_instance.status
      }
      // comment out question objects in new get survey call
      // childQuestions: {
      //   instanceId: item.child_survey_instance.id,
      //   questionId: item.child_survey_instance.status === 'complete' ? '' : item.child_survey_instance.current_question.id,
      //   question: item.child_survey_instance.status === 'complete' ? '' : item.child_survey_instance.current_question.text,
      //   answers: item.child_survey_instance.status === 'complete' ? '' : answer(item.child_survey_instance.current_question.answers),
      //   instructions: item.child_survey_instance.status === 'complete' ? '' : item.child_survey_instance.current_question.instructions,
      //   skippable: item.child_survey_instance.status === 'complete' ? '' : item.child_survey_instance.current_question.skippable,
      //   progress: item.child_survey_instance.status === 'complete' ? '' : item.child_survey_instance.progress,
      //   type: item.child_survey_instance.status === 'complete' ? '' : item.child_survey_instance.current_question.answer_type
      // },
      // guardianQuestions: {
      //   instanceId: item.guardian_survey_instance.id,
      //   questionId: item.guardian_survey_instance.status === 'complete' ? '' : item.guardian_survey_instance.current_question.id,
      //   question: item.guardian_survey_instance.status === 'complete' ? '' : item.guardian_survey_instance.current_question.text,
      //   answers: item.guardian_survey_instance.status === 'complete' ? '' : answer(item.guardian_survey_instance.current_question.answers),
      //   instructions: item.guardian_survey_instance.status === 'complete' ? '' : item.guardian_survey_instance.current_question.instructions,
      //   skippable: item.guardian_survey_instance.status === 'complete' ? '' : item.guardian_survey_instance.current_question.skippable,
      //   progress: item.guardian_survey_instance.status === 'complete' ? '' : item.guardian_survey_instance.progress,
      //   type: item.guardian_survey_instance.status === 'complete' ? '' : item.guardian_survey_instance.current_question.answer_type
      // }
    }
  ));
  return participants;
};

export const mapReport = (data) => ({
  birthday: data.birthday,
  firstName: data.first_name,
  lastName: data.last_name,
  lastAnswerDate: data.last_answer_date,
  questions: data.questions,
  results: {
    child: {
      cat: data.results.child.cat,
      cmrs: data.results.child.cmrs,
      crafft: data.results.child.crafft,
      gad: data.results.child.gad_7,
      mania: data.results.child.mania_history,
      phq9: data.results.child.phq_9,
      sbirt: data.results.child.sbirt
    },
    guardian: {
      cmrs: data.results.guardian.cmrs,
      gad: data.results.guardian.gad_7,
      general: data.results.guardian.general,
      mania: data.results.guardian.mania_history,
      phq9: data.results.guardian.phq_9,
      sbirt: data.results.guardian.sbirt
    }
  }
});

export const mapAdvanced = (data) => ({
  birthday: data.birthday,
  firstName: data.first_name,
  lastName: data.last_name,
  lastAnswerDate: data.last_answer_date,
  questions: data.questions,
  // recommendation: data.recommendations,
  // handouts: data.handouts,
  // script: data.script.replace(new RegExp('\\\\n', 'g'), '<br><br>'),
  results: {
    child: {
      bash: data.results.child.bash || '',
      bash_score: data.results.child.bash_score || '',
      cat: data.results.child.cat || '',
      cat_score: data.results.child.cat_score || '',
      cmrs: data.results.child.cmrs || '',
      cmrs_score: data.results.child.cmrs_score || '',
      crafft: data.results.child.crafft || '',
      crafft_score: data.results.child.crafft_score || '',
      gad: data.results.child.gad_7 || '',
      gad_score: data.results.child.gad_7_score || '',
      mania: data.results.child.mania_history || '',
      mania_score: data.results.child.mania_history_score || '',
      phq9: data.results.child.phq_9 || '',
      phq9_score: data.results.child.phq_9_score || '',
      sbirt: data.results.child.sbirt || '',
      sbirt_score: data.results.child.sbirt_score || '',
      history: data.results.child.treatment_history || '',
      history_score: data.results.child.treatment_history_score || '',
      preference: data.results.child.treatment_preference || '',
      preference_score: data.results.child.treatment_preference_score || '',
      satisfaction: data.results.child.treatment_satisfaction || '',
      satisfaction_score: data.results.child.treatment_satisfaction_score || '',
      readiness: data.results.child.treatment_readiness || '',
      readiness_score: data.results.child.treatment_readiness_score || ''
    },
    guardian: {
      bash: data.results.guardian.bash || '',
      cmrs: data.results.guardian.cmrs || '',
      gad: data.results.guardian.gad_7 || '',
      general: data.results.guardian.general || '',
      mania: data.results.guardian.mania_history || '',
      phq9: data.results.guardian.phq_9 || '',
      history: data.results.guardian.treatment_history || '',
      preference: data.results.guardian.treatment_preference || '',
      satisfaction: data.results.guardian.treatment_satisfaction || ''
    },
    discrepancyChecks: data.discrepancy_checks
  }
});
