import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import uiReducer from './uiReducer';
import questionReducer from './questionReducer';
import historyReducer from './historyReducer';

const rootReducer = combineReducers({
  login: loginReducer,
  ui: uiReducer,
  question: questionReducer,
  history: historyReducer
});

export default rootReducer;
