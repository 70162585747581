const INITIAL_STATE = {
  showPassword: true,
  showMenu: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case 'toggle_eye':
    return {
      ...state,
      showPassword: !state.showPassword
    };
  case 'toggle_menu':
    return {
      ...state,
      showMenu: !state.showMenu
    };
  default:
    return state;
  }
};
