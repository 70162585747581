import React, { Component } from 'react';
import Overlay from '../../common/Overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import '../../assets/scss/_survey.scss';

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }
  render() {
    const { visible } = this.state;
    return (
      <div className="help-view">
        <div className="help-container">
          <FontAwesomeIcon icon={faQuestionCircle} className="help-icon" />
          <button
            onClick={() => this.setState({ visible: true })}
            className="help"
            disabled={visible}
          >
            <p>I need help</p>
          </button>
          <Overlay
            title="Need Help?"
            modalVisible={visible}
            returnToQuestion={() => this.setState({ visible: false })}
            question={this.props.instructions}
            no="Close"
          />
        </div>
      </div>
    );
  }
}

export default Help;
