import React from 'react';
import ChildItemLevelReport from './ChildItemLevelReport'
import ParentItemLevelReport from './ParentItemLevelReport'
import { get } from '../../../util/helperFunctions'

const ItemLevelReport = ({ reportDetails }) => {
  // This is setting up all the questions and answers so we can quickly pass the following
  // variables into the DetailsQuestionTable component for display
  const phq9 = [];
  const crafft = [];
  const sbirt = [];
  const cat = [];
  const cmrs = [];
  const gad = [];
  const phq9parent = [];
  const cmrsParent = [];
  const gadParent = [];

  const childArray = get(['questions', 'child'], reportDetails, [])
  childArray.forEach((q) => {
    // if (q.question_name === 'phq_9' && q.question.startsWith('phq_9: 9')) phqNineNineScore = q.answer_text
    if (q.question_name === 'phq_9') phq9.push(q)
    if (q.question_name === 'crafft') crafft.push(q)
    if (q.question_name === 'sbirt' || q.question_name === 'sbirt_8') sbirt.push(q)
    if (q.question_name.startsWith('child_cat_')) cat.push(q)
    if (q.question_name === 'cmrs') cmrs.push(q)
    if (q.question_name === 'gad7') gad.push(q)
  })

  const parentArray = get(['questions', 'guardian'], reportDetails, [])
  parentArray.forEach((q) => {
    if (q.question_name.startsWith('phq_9') || q.question_name.startsWith('phq_2')) phq9parent.push(q)
    if (q.question_name.startsWith('cmrs')) cmrsParent.push(q)
    if (q.question_name.startsWith('gad')) gadParent.push(q)
  })

  return (
    <>
      <ChildItemLevelReport
        reportDetails={reportDetails}
        phq9Details={phq9}
        crafftDetails={crafft}
        sbirtDetails={sbirt}
        catDetails={cat}
        cmrsDetails={cmrs}
        gadDetails={gad}
      />
      <ParentItemLevelReport
        reportDetails={reportDetails}
        phq9Details={phq9parent}
        cmrsDetails={cmrsParent}
        gadDetails={gadParent}
      />
    </>
  )
}

export default ItemLevelReport
