import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from './pages/Login';
import SurveyPage from './pages/SurveyPage';
import Instructions from './pages/Instructions';
import Reports from './pages/Reports';
import AdvancedReport from './pages/Reports/AdvancedReport';
import SurveyDone from './pages/SurveyDone'

class Main extends Component {
  render() {
    return (
      <Router>
        <Route exact path="/" component={Login} />
        <Route path="/survey" component={SurveyPage} />
        <Route path="/instructions" component={Instructions} />
        <Route path="/report" component={Reports} />
        <Route path="/advanced" component={AdvancedReport} />
        <Route path="/survey-done" component={SurveyDone} />
      </Router>
    );
  }
}

export default Main;
