import { signIn } from '../util/api';

export const logOut = () => ({ type: 'log_out' });
export const clearError = () => ({ type: 'clear_error' });

export const signInToAccount = (email, password) => (dispatch) => {
  signIn(email, password)
    .then((success) => {
      console.log('SIGN IN SUCCESS', success);
      const { client } = success.headers;
      const token = success.headers['access-token'];
      dispatch({ type: 'log_in', uid: email, client, token, password });
    })
    .catch((error) => {
      console.log('SIGN IN FAILURE', error.response, error.message);
      dispatch({ type: 'show_login_error', message: error.response.data.errors[0] });
    });
};
export const saveToken = (token) => (dispatch) => {
  dispatch({ type: 'save_token', surveyToken: token });
}

export const resetLoggedIn = () => ({ type: 'reset_log_in' });
export const resetLoginError = () => ({ type: 'reset_login_error' });
