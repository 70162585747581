import React from 'react';
import Overlay from '../../common/Overlay';

const Disclaimer = (props) => {
  if (props.parentGuardian === 'child' || props.parentGuardian === 'complete') {
    const questions = [
      {
        para: 'We will be asking you some sensitive questions about',
        bold: ' your emotional health.'
      },
      {
        para: 'These are questions',
        bold: ' we ask everyone ',
        secondPara: 'your age.'
      },
      {
        para: '',
        bold: '1 in 5 people',
        secondPara: ' have mental health symptoms and suicide is the second cause of death in young people.'
      },
      {
        para: 'If you are feeling down, it’s',
        bold: ' important ',
        secondPara: 'to get help early.'
      },
      {
        para: '',
        bold: 'We take your confidentiality seriously. ',
        secondPara: 'Only your healthcare provider will see the answers to your questions (and the research team if you are in the study).  The only exception is if you answer that you are at high risk for suicide, then your health care team may need to tell your parent to help keep you safe.'
      },
      {
        para: 'If you do need help, the most common next step is we will',
        bold: ' introduce you ',
        secondPara: 'to a behavioral health specialist who works in our or a nearby office.'
      }
    ];
    return (
      <div>
        <Overlay
          modalVisible={props.visible}
          returnToQuestion={() => props.visibleFunction()}
          question={questions}
          patient
          bullets
          memes={props.memes}
          disclaimer={props.disclaimer}
          memeInfo={props.memeInfo}
        />
      </div>
    );
  }
  const questions = [
    {
      para: 'We will be asking you and your child some sensitive questions about',
      bold: ' their emotional health.'
    },
    {
      para: 'These are questions',
      bold: ' we ask everyone ',
      secondPara: 'their age.'
    },
    {
      para: '',
      bold: '1 in 5 people',
      secondPara: ' have mental health symptoms and suicide is the second cause of death in young people.'
    },
    {
      para: 'If your child is feeling down, it’s',
      bold: ' important ',
      secondPara: 'to get help early.'
    },
    {
      para: '',
      bold: 'We take your confidentiality seriously. ',
      secondPara: 'Only your healthcare provider will see the answers to your questions (and the research team if you are in the study).  The only exception is if you answer that your child is at high risk for suicide, then your health care team may need to tell you to help keep your child safe.'
    },
    {
      para: 'If your child does need help, the most common next step is we will',
      bold: ' introduce you ',
      secondPara: 'to a behavioral health specialist who works in our or a nearby office.'
    }
  ];
  return (
    <div>
      <Overlay
        modalVisible={props.visible}
        returnToQuestion={() => props.visibleFunction()}
        question={questions}
        bullets
      />
    </div>
  );
};

export default Disclaimer;
