import React, { useRef, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FULL, SUMMARY, HANDOUT } from '../../../common/Variables';

const PrintReportMenu = ({ onItemClick }) => {
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const onClick = () => {
    if (isActive) return

    setIsActive(true)
  }

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (dropdownRef.current === null || dropdownRef.current === undefined) return

      if (!dropdownRef.current.contains(e.target)) {
        setIsActive((wasActive) => !wasActive)
      }
    };

    if (isActive) window.addEventListener('click', pageClickEvent);

    return () => window.removeEventListener('click', pageClickEvent);
  }, [isActive]);

  return (
    <div className="print-report-button flex relative">
      <button
        ref={buttonRef}
        className="flex-none text-base font-black bg-sw-primary-blue text-white rounded-full py-2 px-5"
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faPrint} />
        <span className="px-2 text-xs">PRINT REPORT</span>
        <FontAwesomeIcon icon={faChevronDown} />
      </button>
      <nav
        ref={dropdownRef}
        className={`bg-white absolute top-15 right-4 text-sw-light-gray shadow-md border border-border-grey rounded-md ${isActive ? 'block' : 'hidden'}`}
      >
        <ul className="py-2 pl-4 pr-6 text-base">
          <li className="print-menu-item py-2">
            <button onClick={() => onItemClick(FULL.classValue)}>{FULL.text}</button>
          </li>
          <hr />
          <li className="print-menu-item py-2">
            <button onClick={() => onItemClick(SUMMARY.classValue)}>{SUMMARY.text}</button>
          </li>
          <hr />
          <li className="print-menu-item py-2">
            <button onClick={() => onItemClick(HANDOUT.classValue)}>{HANDOUT.text}</button>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default PrintReportMenu