import React, { useEffect, useState } from 'react';
import Button from '../../common/Button';
import Disclaimer from '../Disclaimer';
import { connect } from 'react-redux';
import { getAdvancedReport } from '../../util/api';
import { catSSAlert, phq9AlertChild, phq9AlertGuardian } from '../Reports/AdvancedReport/alertBlockHelpers';
import Spinner from '../../common/Spinner';
import RedcapRedirectScreen from './RedcapRedirectScreen';
import { mapAdvanced } from '../../actions/functions';
import '../../assets/scss/_survey.scss';

const ThankYou = ({
  disclaimerVisible,
  visibleFunction,
  submitButton,
  surveyToken,
  uid,
  client,
  token,
  child
}) => {
  const [showRedcapRedirect, setShowRedcapRedirect] = useState(false);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function retrieveReport() {
      const res = await getAdvancedReport(token, uid, client, surveyToken)
      const report = mapAdvanced(res.data);
      const isChildAndSuicidal = child && (phq9AlertChild(report) !== '' || catSSAlert(report) !== '');
      const isParentAndSuicidal = !child && phq9AlertGuardian(report) !== '';
      if (isChildAndSuicidal || isParentAndSuicidal) {
        setShowRedcapRedirect(true);
      }
      setLoading(false);
    }

    retrieveReport()
    
  }, [surveyToken, uid, client, token, child])

  if (loading) {
    return (
      <div className="flex self-center pt-16">
        <Spinner />
      </div>
    );
  }

  if (showRedcapRedirect) {
    return <RedcapRedirectScreen onClick={submitButton} />
  }

  const disclaimerComp = disclaimerVisible && <Disclaimer
    visible
    memeInfo="meme4"
    memes
    parentGuardian="child"
    visibleFunction={visibleFunction}
  />;

  return (
    <div className="thank-you-container">
      {disclaimerComp}
      <div className="thank-you-container">
        <p className="thank-you-text">
          Thank you for taking this survey!
        </p>
        <p className="thank-you-text">
          You may click Submit and close the browser window.
        </p>
        <div className="submit-btn-padding">
          <Button
            disabled={false}
            buttonText="Submit"
            onClick={submitButton}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ login, question }) => {
  const { surveyToken, uid, client, token } = login;
  const { child } = question;
  return {
    surveyToken,
    uid,
    client,
    token,
    child
  }
}

export default connect(mapStateToProps, {})(ThankYou);
