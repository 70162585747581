import React from 'react';
import DetailsQuestionTable from '../DetailsQuestionTable'
import AlertCard from '../../AlertCard'

// Hardcoded variables here that we use to determine severity of answers
// These affect the styling and will need to be updated if the rails portal
// logic is ever updated
const SBIRT_RESULT = 'Positive'

const ChildSbirt = ({ sbirt, sbirtDetails }) => {
  var substanceColor = 'grey'
  var substanceText = 'N/A'
  if (sbirt && sbirt !== 'No answer given') {
    const substanceIsPositive = sbirt.includes(SBIRT_RESULT)
    substanceColor = substanceIsPositive ? 'red' : 'green'
    substanceText = substanceIsPositive ? 'Yes' : 'No'
  }

  return (
    <div className="mx-auto mb-8">
      <p className="text-xs font-bold text-center pt-8 border-t-2 border-sw-lightest-gray">
        ADOLESCENT REPORT
      </p>
      <p className="text-xl text-center font-bold text-sw-dark-blue pt-2">
        Substance Use
      </p>
      <div className="w-full mx-auto flex justify-center">
        <div className="w-3/4 sm:w-1/3 flex justify-center mt-4 mb-8">
          <AlertCard
            color={substanceColor}
            text={substanceText}
          />
        </div>
      </div>
      <DetailsQuestionTable questionType={sbirtDetails} />
    </div>
  )
}

export default ChildSbirt
