import React from 'react';
import Button from '../../common/Button';
import '../../assets/scss/_confirm.scss';

const ButtonContainer = (props) => (
  <div className="btn-contain">
    <Button
      buttonText="Next"
      disabled={props.disabled}
      extraPrimaryStyle="btn-contain-button"
      extraPrimaryTextStyle="btn-contain-txt"
      extraBtnStyle="purple-bg"
      onClick={() => props.nextFunction()}
    />
  </div>
);

export default ButtonContainer;
