import React from 'react';
import { substanceNotes } from '../tableNotes'

const SubstanceUseRow = ({ advanceReport }) => {
  const { crafft } = advanceReport.results.child
  
  var crafftScore = "N/A"
  if (crafft && crafft !== "No answer given") {
    crafftScore = `${crafft.charAt(crafft.length - 1)}/6`
  }

  const substanceUseChildAnswers = () => {
    const { child } = advanceReport.questions

    return child.map((q) => {
      if (q.question_name !== 'sbirt' || q.answer_text === 'Never') return null

      return (
        <div key={q.question_text}>
          {q.question_text} : {' '}
          <span className="font-normal">
            {q.answer_text}
          </span>
        </div>
      )
    })
  }

  return (
    <div className="table-row border-t-2 border-b-2 border-sw-lightest-gray align-top" role="row">
      <div className="table-cell font-bold text-lg text-sw-dark-blue py-3" role="rowheader">
        Substance
        <br />
        Abuse
      </div>
      <div className="table-cell text-xs text-sw-dark-blue font-bold py-3 pr-3 align-top leading-loose" role="gridcell">
        {substanceUseChildAnswers()}
        CRAFFT score = {crafftScore}
      </div>
      <div className="table-cell text-xs text-sw-dark-blue font-bold py-3 pr-3 align-top leading-loose" role="gridcell">
        Not Asked
      </div>
      <div className="table-cell align-top" role="gridcell">
        {substanceNotes(advanceReport)}
      </div>
    </div>
  )
}

export default SubstanceUseRow