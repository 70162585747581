import axios from 'axios';
import { url } from '../common/Variables';

const newURL = `${url}/api`;

const instance = axios.create({
  baseURL: newURL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Accept':       'application/json'
  }
});

export default instance;
