import React from 'react';

const PHQ_Q_9_DEFAULT = "Not at all"
const CAT_DEFAULT = "Low or no risk"
const PHQ_DEFAULT = 'Minimal'
const DEFAULT_EMPTY = 'No answer given'
const GAD_DEFAULT = 'Minimal'
const CMRS_DEFAULT = "No concern"
const SUBSTANCE_SCORE_DEFAULT = "0"

const findPhq9AnswerText = (arr) => {
  const obj = arr.find((p) => p.question.startsWith('phq_9: 9'))
  if (obj) return obj.answer_text.split('.').join("")
  
  return PHQ_Q_9_DEFAULT
}

export const suicidalityNotes = (report) => {
  const { questions, results } = report
  const { cat } = results.child;

  const catScore = cat ? cat.split('.').join("") : CAT_DEFAULT

  const parentText = findPhq9AnswerText(questions.guardian)
  const childText = findPhq9AnswerText(questions.child)

  if (parentText === PHQ_Q_9_DEFAULT && childText === PHQ_Q_9_DEFAULT && catScore === CAT_DEFAULT) {
    return <div><p></p></div>
  }

  return (
    <div className="text-xs py-3 align-top leading-loose">
      <p>
        ***Assess for safety risk
      </p>
      <p>
        (Epic phrase .SWsafetyplan)
      </p>
    </div>
  )
}

export const phqNotes = (report) => {
  const { guardian, child } = report.results

  const parentAnswers = guardian.phq9 ? guardian.phq9 : PHQ_DEFAULT
  const childAnswers = child.phq9 ? child.phq9 : PHQ_DEFAULT

  const showNotes = !parentAnswers.includes(PHQ_DEFAULT) &&
                    !childAnswers.includes(PHQ_DEFAULT) &&
                    !parentAnswers.includes(DEFAULT_EMPTY) &&
                    !childAnswers.includes(DEFAULT_EMPTY)

  if (showNotes) {
    return (
      <div className="text-xs py-3 leading-loose">
        See SW Detailed report for full PHQ-9 items
      </div>
    )
  }
}

export const gadNotes = (report) => {
  const { guardian, child } = report.results

  const parentAnswers = guardian.gad ? guardian.gad : GAD_DEFAULT
  const childAnswers = child.gad ? child.gad : GAD_DEFAULT

  const showNotes = !parentAnswers.includes(GAD_DEFAULT) &&
                    !childAnswers.includes(GAD_DEFAULT) &&
                    !parentAnswers.includes(DEFAULT_EMPTY) &&
                    !childAnswers.includes(DEFAULT_EMPTY)

  if (showNotes) {
    return (
      <p className="text-xs py-3 leading-loose">
        See SW Detailed report for full GAD-7 items
      </p>
    )
  }
}

export const maniaNotes = (report) => {
  const { guardian, child } = report.results

  const parentAnswers = guardian.cmrs ? guardian.cmrs : CMRS_DEFAULT
  const childAnswers = child.cmrs ? child.cmrs : CMRS_DEFAULT

  if (parentAnswers === CMRS_DEFAULT && childAnswers === CMRS_DEFAULT) return null

  return (
    <p className="text-xs py-3 leading-loose">
      <span>
        Interview to assess further risk.
      </span>
      See SW Detailed report for full Child Mania Rating Scale.
    </p>
  )
}

export const substanceNotes = (advanceReport) => {
  const { crafft, sbirt } = advanceReport.results.child

  var crafftScore = "0"
  if (crafft && crafft !== "No answer given") {
    crafftScore = crafft.charAt(crafft.length - 1)
  }

  var sbirtScore = "0"
  if (sbirt && sbirt !== "No answer given") {
    sbirtScore = sbirt.charAt(0)
  }

  if (sbirtScore === SUBSTANCE_SCORE_DEFAULT && crafftScore === SUBSTANCE_SCORE_DEFAULT) return null
  
  return (
    <p className="text-xs py-3 leading-loose">
      See SW Detailed Report for full SBIRT / CRAFFT items
    </p>
  )
}
