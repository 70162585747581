import React from 'react';
import '../../assets/scss/_survey.scss';

const RedcapRedirectScreen = ({ onClick }) => {

  return (
    <div className="flex flex-col">
      <p className="thank-you-text pt-4">
        Thank you for completing the survey.
      </p>
      <p className="thank-you-text pt-2">
        Your responses indicate that you may be eligible for a research study looking to see if the screen you just took (Screening Wizard) can improve mental health care in adolescents.
      </p>
      <div className="flex flex-col pt-6">
        <p className="italic thank-you-text">Why should I do a study?</p>
        <p className="thank-you-text">
          Without people like you, we can't know if tools like Screening Wizard prevent adolescent suicide and depression.
        </p>
      </div>
      <div className="flex flex-col pt-6">
        <p className="italic thank-you-text">Will it take a long time?</p>
        <p className="thank-you-text">
          No, you will have to participate in two phone calls - one in a week or so, and another in three months. There will also be a brief survey to complete in three months.
        </p>
      </div>
      <div className="flex flex-col pt-6">
        <p className="italic thank-you-text">Do I get compensated?</p>
        <p className="thank-you-text">
          Yes! You will get a gift card for participating.
        </p>
      </div>
      <div className="flex flex-col pt-6">
        <p className="italic thank-you-text">What now?</p>
        <p className="thank-you-text">
          Click this link to provide your contact info so that we have permission to reach out to you:
        </p>
        <a href="https://redcap.link/screeningwizard" rel="noreferrer" target="_blank" onClick={onClick} className="flex self-center underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
          Redcap Link
        </a>
      </div>
    </div>
  )
}

export default RedcapRedirectScreen;
