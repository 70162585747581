import React from 'react';
import '../../assets/scss/_login.scss';

const MissingTokenView = () => (
  <div className="background-image">
    <div className="main-view">
      <div className="login-title">
        The URL you entered is invalid.<br />
        Please use the link that was sent to you.
      </div>
    </div>
  </div>
);

export default MissingTokenView;