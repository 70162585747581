import React from 'react';

const progress = (percentage) => {
  const int = parseInt(percentage, 10);
  if (int > 0) {
    const newPercent = percentage.slice(0, percentage.length - 1);
    const rounded = Math.floor(newPercent);
    const finalPercent = `${rounded}%`;
    return finalPercent;
  } return '0%';
};

const ProgressBar = ({ percentage }) => {
  const int = parseInt(percentage, 10);
  return (
    <div className="bar-container">
      <div className="purple-container" style={ int > 10 ? { width: `${int}%` } : { width: '10%' } }>
        <p className="percentage">
          {progress(percentage)}
        </p>
      </div>
    </div>
  );
}

export default ProgressBar;
