import axios from './axios';

const authHeaders = (accessToken, uid, client) => {
  return {
    'Access-Token': accessToken,
    'Uid': uid,
    'Client': client,
    'Token-Type': 'Bearer'
  };
};

export const signIn = (e, p) => (
  axios({
    method: 'POST',
    url: `/auth/sign_in?email=${e}&password=${p}`,
    data: {
      email: e,
      password: p
    }
  })
);

export const getSurvey = (token, uid, client, date) => (
  axios({
    method: 'GET',
    url: `/v1/survey_instances?scheduled_to=${date}`,
    headers: authHeaders(token, uid, client)
  })
);

export const getQuestion = (token, uid, client, id) => (
  axios({
    method: 'GET',
    url: `/v1/survey_instances/${id}`,
    headers: authHeaders(token, uid, client)
  })
);

export const getAllSurveys = (token, uid, client) => (
  axios({
    method: 'GET',
    url: '/v1/all_survey_instances',
    headers: authHeaders(token, uid, client)
  })
);

export const updateGuardian = (token, uid, client, data, id) => (
  axios({
    method: 'PATCH',
    url: `/v1/survey_instances/${id}`,
    data,
    headers: authHeaders(token, uid, client)
  })
);

export const getReport = (token, uid, client, id) => (
  axios({
    method: 'GET',
    url: `/v1/survey_instances/${id}/reports/detailed`
    // headers: authHeaders(token, uid, client)
  })
);

export const getAdvancedReport = (token, uid, client, id) => (
  axios({
    method: 'GET',
    url: `/v1/survey_instances/${id}/reports/advanced`
    // headers: authHeaders(token, uid, client)
  })
);

export const answerQuestion = (token, uid, client, data, id) => (
  axios({
    method: 'POST',
    url: `/v1/survey_instances/${id}/answers`,
    data,
    headers: authHeaders(token, uid, client)
  })
);
