import React from 'react';
import Spinner from '../../../common/Spinner';

const Loading = () => (
  <div className="report-main-container">
    <p style={{ fontSize: 20, textAlign: 'center' }}>
      SCREENING WIZARD RESULTS SUMMARY
    </p>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Spinner />
    </div>
  </div>
);

export default Loading;