import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faQuestion,
  faCheck,
  faHandPaper,
} from '@fortawesome/free-solid-svg-icons'
import { get } from '../../../../util/helperFunctions';

export const readinessIcon = (data) => {
  if (!data) return null

  if (data.answer.includes('I am unsure')) {
    return (faQuestion)
  }
  if (data.answer.includes('Yes')) {
    return (faCheck)
  }
  if (data.answer.includes('No')) {
    return (faHandPaper)
  }
  return null
}

export const childReadiness = (advanceReport) => {
  if (!advanceReport.questions.child) return null

  const data = advanceReport.questions.child.find((q) => q.question_name === 'readiness_for_tx')
  if (!data) return null

  return data
}

export const guardianReadiness = (advanceReport) => {
  if (!advanceReport.questions.guardian) return null

  const data = advanceReport.questions.guardian.find((q) => q.question_name === 'treatment_readiness')
  if (!data) return null

  return data
}

export const childPreference = (advanceReport) => {
  const childArray = get(['questions', 'child'], advanceReport, [])
  return childArray.find((q) => q.question_name === 'treatment_preference')
}

export const guardianPreference = (advanceReport) => {
  const guardianArray = get(['questions', 'guardian'], advanceReport, [])
  return guardianArray.find((q) => q.question_name === 'treatment_preference')
}

export const notAnsweredIconAndText = () => (
  <div className="flex flex-row">
    <div className="mx-4">
      <FontAwesomeIcon
        icon={faQuestion}
        className="text-4xl text-sw-dark-blue"
      />
    </div>
    <div className="flex flex-wrap content-center">
      N/A
    </div>
  </div>
)