/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import useScrollPosition from '../useScrollPosition'

const PatientHandoutHeader = React.forwardRef((props, ref) => {
  const { thresholdcrossed = () => null } = props

  useScrollPosition(
    ({ currPos, prevPos }) => {
      if (currPos.y === 210 || (currPos.y > 210 && prevPos.y < 210) || (currPos.y < 210 && prevPos.y > 210)) {
        thresholdcrossed()
      }
    }, [], ref
  )

  return (
    <div className="container">
      <a
        ref={ref}
        className="handout-header flex text-3xl font-black text-sw-dark-blue  leading-10 py-8 border-t-8 border-sw-dark-blue"
        name="patient-handout"
      >
        Screening Wizard Patient Handout
      </a>
    </div>
  )
})

export default PatientHandoutHeader
