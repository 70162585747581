import React from 'react';


const ClinicalCareResources = () => {
  return (
    <div className="cc-resources container text-xs pb-10">
      <span className="font-bold text-sw-dark-blue">
        Guidelines for Adolescent Depression in Primary Care (GLAD-PC) Toolkit: &nbsp;
      </span>
      <a
        href="http://www.gladpc.org/ "
        target="_blank"
        className="text-sw-primary-blue"
        rel="noopener noreferrer"
      >
        http://www.gladpc.org/
      </a>
      <br />
      <span className="font-bold text-sw-dark-blue">
        The GLAD-PC Toolkit includes:
      </span>
      <ul className="list-disc pl-8">
        <li>GLAD-PC Clinical Assessment Flowcharts which are free and easy to use</li>
        <li>DSM-5 criteria for diagnosis of depression and mood disorders</li>
        <li>Screening tools</li>
        <li>Treatment information</li>
        <li>Educational materials for adolescents and parents</li>
        <li>Billing guidance (AAP Coding Fact Sheet)</li>
      </ul>
      <br />
      <span className="font-bold text-sw-dark-blue">
        American Academy of Child and Adolescent Psychiatry Practice Parameters:
      </span>
      <ul className="list-disc pl-8">
        <li> Anxiety &nbsp;
          <a
            href="http://www.jaacap.org/article/S0890-8567(09)66583-7/fulltext"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sw-primary-blue"
          >
            https://www.jaacap.org/article/S0890-8567(09)66583-7/fulltext
          </a>
        </li>
        <li> Depression &nbsp;
          <a
            href="http://www.jaacap.org/article/S0890-8567(09)62053-0/fulltext"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sw-primary-blue"
          >
            https://www.jaacap.org/article/S0890-8567(09)62053-0/fulltext
          </a>
        </li>
        <li> Suicidal Behavior &nbsp;
          <a
            href="http://www.jaacap.org/article/S0890-8567(09)60404-4/fulltext"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sw-primary-blue"
          >
            https://www.jaacap.org/article/S0890-8567(09)60404-4/fulltext
          </a>
        </li>
      </ul>
      <br />
      <span className="font-bold text-sw-dark-blue">
        AAP Pediatric Mental Health Minute Series &nbsp;
      </span>
      <a
        href="http://services.aap.org/en/patient-care/mental-health-minute/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-sw-primary-blue"
      >
        https://services.aap.org/en/patient-care/mental-health-minute/
      </a>
      <ul className="list-disc pl-8">
        <li>Short videos and associated articles – open access – to provide guidance for pediatricians on in oﬃce mental health management</li>
        <li>Relevant topics include: Anxiety, Adolescent Depression, Suicide, Antidepressants, and Antidepressant Side Eﬀects </li>
      </ul>
      <br />
      <span className="font-bold text-sw-dark-blue">
        Suicide Prevention Toolkit for Primary Care Practices &nbsp;
      </span>
      <a
        href="http://www.sprc.org/settings/primary-care/toolkit?sid=49706"
        target="_blank"
        rel="noopener noreferrer"
        className="text-sw-primary-blue"
      >
        https://www.sprc.org/settings/primary-care/toolkit?sid=49706
      </a>
      <ul className="list-disc pl-8">
        <li>Includes learning module for suicide risk assessment and intervention</li>
        <li>Includes Safety Planning Guide and Patient Safety Plan Template</li>
      </ul>
      <br />
      <span className="font-bold text-sw-dark-blue">
        Ask Suicide-Screening Questions (ASQ) Toolkit &nbsp;
      </span>
      <a
        href="http://www.nimh.nih.gov/research/research-conducted-at-nimh/asq-toolkit-materials/index.shtml"
        target="_blank"
        rel="noopener noreferrer"
        className="text-sw-primary-blue"
      >
        https://www.nimh.nih.gov/research/research-conducted-at-nimh/asq-toolkit-materials/index.shtml
      </a>
      <ul className="list-disc pl-8">
        <li> Brief Suicide Safety Assessment Guide, Risk Screening Tool, Videos Available</li>
      </ul>
    </div>
  )
}

export default ClinicalCareResources;
