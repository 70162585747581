/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import useScrollPosition from '../useScrollPosition'

const PreferencesHeader = React.forwardRef((props, ref) => {
  const { thresholdcrossed = () => null } = props
  
  useScrollPosition(
    ({ currPos, prevPos }) => {
      if (currPos.y === 210 || (currPos.y > 210 && prevPos.y < 210) || (currPos.y < 210 && prevPos.y > 210)) {
        thresholdcrossed()
      }
    }, [], ref
  )

  return (
    <div className="preferences-header container">
      <a
        ref={ref}
        className="text-3xl flex font-black text-sw-dark-blue leading-10 py-8 border-t-8 border-sw-dark-blue print-no-pb"
        name="readiness-preferences-barriers"
      >
        Readiness, Preferences, & Barriers
      </a>
    </div>
  )
})

export default PreferencesHeader
