import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBan,
  faCapsules,
  faComments,
} from '@fortawesome/free-solid-svg-icons'
import { notAnsweredIconAndText, readinessIcon } from './treatmentFunctions'

const preferenceOutput = (data) => {
  if (!data) return notAnsweredIconAndText();

  const { answer } = data

  const isMedOnly = answer.includes('medication only')
  const isTalkOnly = answer.includes('talk therapy only')
  const isBoth = answer.includes('medication and talk')
  const isNotInterested = !isMedOnly && !isTalkOnly && !isBoth

  if (isNotInterested || isMedOnly || isTalkOnly) {
    var iconName = null
    if (isNotInterested) iconName = faBan
    if (!iconName && isMedOnly) iconName = faCapsules
    if (!iconName && !isMedOnly) iconName = faComments
    
    return (
      <div className="flex flex-row">
        <div className="mx-4">
          <FontAwesomeIcon
            icon={iconName}
            className="text-4xl text-sw-dark-blue"
          />
        </div>
        <div className="flex flex-wrap content-center">
          {data.answer_text}
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-row">
      <div className="flex flex-col mx-4">
        <div className="">
          <FontAwesomeIcon
            icon={faCapsules}
            className="text-2xl text-sw-dark-blue"
          />
        </div>
        <div className="">
          <FontAwesomeIcon
            icon={faComments}
            className="text-2xl text-sw-dark-blue"
          />
        </div>
      </div>
      <div className="flex flex-wrap content-center">
        {data.answer_text}
      </div>
    </div>
  )
}

const readinessOutput = (data) => {
  if (!data) return notAnsweredIconAndText();

  const icon = readinessIcon(data)
  if (!icon) return <div className="bold">{data.answer_text}</div>

  return (
    <div className="flex flex-row">
      <div className="mx-4">
        <FontAwesomeIcon
          icon={icon}
          className="text-4xl text-sw-dark-blue"
        />
      </div>
      <div className="flex flex-wrap content-center">
        {data.answer_text}
      </div>
    </div>
  )
}

const PreferenceCard = ({ readinessData, preferenceData }) => {
  return (
    <div className="preference-card filter drop-shadow-md bg-white rounded p-4 pr-8 pb-5">
        <div className="text-xs font-bold mb-4">
          READINESS FOR TREATMENT
        </div>
        <div className="mb-8">
          {readinessOutput(readinessData)}
        </div> 
        <div className="text-xs font-bold mb-4">
          PREFERENCE FOR TREATMENT
        </div>
        <div className="mb-8 print-no-mb">
          {preferenceOutput(preferenceData)}
        </div>
    </div>
  )
}

export default PreferenceCard;
