import React from 'react';
import DetailsQuestionTable from '../DetailsQuestionTable'
import AlertCard from '../../AlertCard'

// Hardcoded variables here that we use to determine severity of answers
// These affect the styling and will need to be updated if the rails portal
// logic is ever updated
const PHQ_NEGATIVE_RESULT = 'No Depression'

const showSubheader = (phq9Details) => {
  if (!phq9Details || phq9Details.length === 0) return null;

  return (
    <p className="text-s font-bold text-sw-dark-blue pb-6">
      How often has your child been affected by each of the following symptoms in the past 2 weeks?
    </p>
  )
}

const ParentPhq9 = ({ phq9, phq9Details }) => {
  if (!phq9) return null;

  // If phq9 wasn't answered it can sometimes say "No answer given" which breaks the split on the comma
  // We'll just populate the score for phq9 as 'N/A' in this case
  var phq9ScoreAndText = phq9.split(',')
  if (phq9ScoreAndText.length === 1) phq9ScoreAndText = ['N/A', phq9ScoreAndText[0]]

  var phq9Color = 'grey'
  if (phq9ScoreAndText[0] !== 'N/A') {
    phq9Color = phq9.includes(PHQ_NEGATIVE_RESULT) ? 'green' : 'red'
  }

  const phq9Text = phq9ScoreAndText[1] ? phq9ScoreAndText[1].split('.').join("") : "No Answer Given"
  const phq9ExtraText = `Total Score = ${phq9ScoreAndText[0]}`

  return (
    <div className="mx-auto mb-8">
      <p className="text-xs font-bold text-center">
        PARENT REPORT
      </p>
      <p className="text-xl text-center font-bold text-sw-dark-blue pt-2">
        Depression (PHQ-9)
      </p>
      <div className="w-full mx-auto flex justify-center">
        <div className="w-3/4 sm:w-1/3 flex justify-center mt-4 mb-8">
          <AlertCard
            color={phq9Color}
            text={phq9Text}
            extraText={phq9ExtraText}
          />
        </div>
      </div>
      {showSubheader(phq9Details)}
      <DetailsQuestionTable questionType={phq9Details} />
    </div>
  )
}

export default ParentPhq9
