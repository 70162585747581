export default (questionModule, answer) => {
  if (questionModule.includes('phq')) {
    if (answer === "No") return { fontWeight: 300 }
    if (answer.includes('Not at all')) return { fontWeight: 300 }
    return { fontWeight: 800 }
  } if (questionModule.includes('cat')) {
    if (questionModule === "child_cat_11") {
      if (answer.includes('Not at all')) return { fontWeight: 800 }
      return { fontWeight: 300 }
    }
    if (answer.includes('False') || answer.includes('Not at all')) return { fontWeight: 300 }
    return { fontWeight: 800 }
  } if (questionModule.includes('crafft')) {
    if (answer.includes('No')) return { fontWeight: 300 }
    return { fontWeight: 800 }
  } if (questionModule.includes('sbirt')) {
    if (answer.includes('Never')) return { fontWeight: 300 }
    return { fontWeight: 800 }
  } if (questionModule.includes('cmrs')) {
    if (answer.includes('Never/Rarely')) return { fontWeight: 300 }
    return { fontWeight: 800 }
  } if (questionModule.includes('gad')) {
    if (answer.includes('Not')) return { fontWeight: 300 }
    return { fontWeight: 800 }
  } if (questionModule.startsWith('treatment')) {
    if (answer.includes('No')) return { fontWeight: 300 }
    return { fontWeight: 800 }
  } if (questionModule.startsWith('ams')) {
    if (answer.includes('No worried at all')) return { fontWeight: 300 }
    return { fontWeight: 800 }
  } if (questionModule.startsWith('bash')) {
    if (answer.includes('Disagree')) return { fontWeight: 300 }
    return { fontWeight: 800 }
  }
}
