import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEye } from '@fortawesome/free-solid-svg-icons'
import '../assets/scss/_common.scss';

const renderImage = (props) => {
  if (!props.correct) {
    return (
      <div className="icon-container">
        <FontAwesomeIcon icon={faCheck} className="icon" />
      </div>
    );
  }
  if (props.imageName === 'Eye') {
    return (
      <button
        onClick={() => props.toggleEye()}
        className="icon-container"
      >
        <FontAwesomeIcon icon={faEye} className="icon" />
      </button>
    );
  }
  return null;
}
const NewTextField = (props) => {
  return (
    <div className="container-style">
      <input
        type={props.seePassword && props.type === 'password' ? 'text' : props.type}
        data-lpignore={props.data}
        placeholder={props.placeholder}
        value={props.value}
        name={props.name}
        className={props.className}
        onChange={(e) => props.onChange(e)}
      />
      {renderImage(props)}
    </div>
  );
}


export default NewTextField;
