import React from 'react'
import AlertCard from '../AlertCard'
import { treatment } from './helperFunctions'

const TreatmentCardsRow = ({ advanceReport }) => {
  const { child, childSat, guardian, guardianSat } = treatment(advanceReport)

  var childColor = child === 'Yes' ? 'green' : 'red'
  if (!child) childColor = 'grey'

  var guardianColor = guardian === 'Yes' ? 'green' : 'red'
  if (!guardian) guardianColor = 'grey'

  var childSatColor = childSat === 'Yes' ? 'green' : 'red'
  if (!childSat) childSatColor = 'grey'

  var guardianSatColor = guardianSat === 'Yes' ? 'green' : 'red'
  if (!guardianSat) guardianSatColor = 'grey'

  var childText = child === 'Yes' ? 'CURRENTLY in treatment' : 'NOT CURRENTLY in treatment'
  var guardianText = guardian === 'Yes' ? 'CURRENTLY in treatment' : 'NOT CURRENTLY in treatment'
  var childSatText = childSat === 'Yes' ? 'SATISFIED with treatment' : 'NOT SATISFIED with treatment'
  var guardianSatText = guardianSat === 'Yes' ? 'SATISFIED with treatment' : 'NOT SATISFIED with treatment'

  return (
    <div className="treatment-cards-row container">
      <div className="flex flex-col justify-between md:flex-row md:space-x-3  mt-6 mb-12 space-y-5 md:space-y-0 ">
        <div className="md:w-1/2">
          <div className="text-xs font-bold mb-2">
            ADOLESCENT REPORTS
          </div>
          <div className="flex space-x-3 text-xs">
            <AlertCard color={childColor} text={childText} />
            <AlertCard color={childSatColor} text={childSatText} />
          </div>
        </div>
        <div className="md:w-1/2">
          <div className="text-xs font-bold mb-2 ">
            PARENT REPORTS
          </div>
          <div className="flex space-x-3 text-xs">
            <AlertCard color={guardianColor} text={guardianText} />
            <AlertCard color={guardianSatColor} text={guardianSatText} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default TreatmentCardsRow
