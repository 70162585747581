import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

const AlertCard = ({ color, text, extraText }) => {
  // Default variables set here
  var colorClasses = 'bg-sw-light-green'
  var iconName = faCheckCircle
  var textcolorClasses = 'text-sw-primary-green'
  var extraTextcolorClasses = 'text-sw-dark-blue'

  // If the alert is red, we reset the variables here
  if (color === 'red') {
    colorClasses = 'bg-sw-light-red'
    iconName = faExclamationTriangle
    textcolorClasses = 'text-sw-primary-red'
  }

  // If the alert is grey, we reset here
  if (color === 'grey') {
    colorClasses = 'bg-sw-light-blue'
    textcolorClasses = 'text-sw-light-gray'
    extraTextcolorClasses = 'text-sw-light-gray'
  }

  return (
    <div className="alert-card flex w-full">
      <div className={`alert-card flex justify-center items-center rounded w-full py-2 ${colorClasses}`}>
        <div>
          <div className="flex mt-5 justify-center">
            <FontAwesomeIcon icon={iconName} className={`text-5xl ${textcolorClasses}`}/>
          </div>
          <p className={`text-s font-bold leading-loose text-center px-5 pt-5 pb-2 ${textcolorClasses}`}>
            {text}
          </p>
          <p className={`text-s font-bold text-center pb-2 text-center ${extraTextcolorClasses}`}>
            {extraText}
          </p>
        </div>
      </div>
    </div>
  )
}
export default AlertCard
