export const addToHistory = (routeName, historyList) => (dispatch) => {
  const newHistoryList = [...historyList, routeName];
  dispatch({ type: 'update_history', newHistoryList });
  dispatch({ type: 'set_current_screen', routeName });
};

export const removeLastHistoryItem = (historyList) => {
  const newHistoryList = [...historyList];
  newHistoryList.pop();
  return ({ type: 'update_history', newHistoryList });
};

export const setCurrentScreen = (routeName) => ({ type: 'set_current_screen', routeName });
