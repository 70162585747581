import React from 'react';
import DetailsQuestionTable from './DetailsQuestionTable'
import ParentGad from './ParentItemLevelSections/ParentGad'
import ParentPhq9 from './ParentItemLevelSections/ParentPhq9'
import ParentMania from './ParentItemLevelSections/ParentMania'
import { get } from '../../../util/helperFunctions'
import ParentEmotional from './ParentItemLevelSections/ParentEmotional';

const ItemLevelReport = ({
  reportDetails,
  phq9Details,
  cmrsDetails,
  gadDetails
}) => {
  if (reportDetails.questions.guardian.length === 0) {
    return (
      <div className="parent-item-level-report container">
        <div className="basic-item-header text-3xl font-black text-sw-dark-blue leading-10 py-8 border-t-8 border-sw-dark-blue">
          Screening Wizard Item Level Report: <span className="underline">Parent</span>
        </div>
      </div>
    )
  }

  // The get() function allows us to dig through the object for an answer and default to an empty string.
  // Per the above TODO, we might want to default to something else like null and allow smaller components
  // to run a check and render some default information if necessary
  const phq9 = get(['results', 'guardian', 'phq9'], reportDetails, '')
  const gad = get(['results', 'guardian', 'gad'], reportDetails, '')
  const cmrs = get(['results', 'guardian', 'cmrs'], reportDetails, '')
  const mania = get(['results', 'guardian', 'mania'], reportDetails, '')
  const general = get(['results', 'guardian', 'general'], reportDetails, '')

  return (
    <div className="parent-item-level-report container">
      <div className="basic-item-header text-3xl font-black text-sw-dark-blue leading-10 py-8 border-t-8 border-sw-dark-blue">
        Screening Wizard Item Level Report: <span className="underline">Parent</span>
      </div>

      {/* PHQ-9 */}
      <ParentPhq9 phq9={phq9} phq9Details={phq9Details} />

      {/* GAD */}
      <ParentGad gad={gad} gadDetails={gadDetails} />

      <div className="mx-auto mb-8 border-t-2 border-sw-lightest-gray">
        {/* MANIA */}
        <ParentMania reportDetails={reportDetails} mania={mania} cmrs={cmrs} />
        <DetailsQuestionTable questionType={cmrsDetails} />

        {/* EMOTIONAL CONCERN */}
        <ParentEmotional general={general} />
      </div>
    </div>
  )
}

export default ItemLevelReport
