import React from 'react';
import Overlay from './Overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import '../assets/scss/_common.scss';

const Header = (props) => {
  if (props.left) {
    return (
      <div className="header-container">
        <div className="sides">
          <button
            onClick={() => props.leftFunction()}
            className="render-right"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="icon" />  
            <p className="side-text">
              {props.leftText}
            </p>
          </button>
        </div>
        <Overlay
          modalVisible={props.visible}
          exit
          title="Exit Survey"
          question="Are you sure you want to exit this survey?"
          returnToQuestion={() => props.closeModal()}
          exitAndSave={() => props.exit()}
        />
        <div className="text-container">
          <div>
            <p className="text-headline">
              {props.headline}
            </p>
            <p className="text-headline">
              {props.description}
            </p>
          </div>
        </div>
        <div className="sides">
          <button
            onClick={() => props.rightFunction()}
            className="render-right"
          >
            <p className="side-text">
              {props.rightText}
            </p>
            <FontAwesomeIcon icon={faTimes} className="icon" />
          </button>
        </div>
      </div>
    )
  } return (
    <div className="header-container">
      <div className="sides" />
      <Overlay
        modalVisible={props.visible}
        exit
        title="Exit Survey"
        question="Are you sure you want to exit this survey?"
        returnToQuestion={() => props.closeModal()}
        exitAndSave={() => props.exit()}
      />
      <div className="text-container">
        <div>
          <p className="text-headline">
            {props.headline}
          </p>
          <p className="text-headline">
            {props.description}
          </p>
        </div>
      </div>
      <div className="sides">
        <button
          onClick={() => props.rightFunction()}
          className="render-right"
        >
          <p className="side-text">
            {props.rightText}
          </p>
          <FontAwesomeIcon icon={faTimes} className="icon" />
        </button>
      </div>
    </div>
  );
}

export default Header;
