import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

const discrepancyCell = (q) => {
  if (q.child === q.parent || q.parent === '' || q.child === '') {
    return <div></div>
  }

  return (
    <FontAwesomeIcon
      icon={faExclamationTriangle}
      className="text-sw-primary-red text-xl"
    />
  )
}

const BarrierRow = ({ q, title, icon }) => {
  const boldChild = q.child === "Strongly Agree" ? 'font-bold' : '';
  const boldParent = q.parent === "Strongly Agree" ? 'font-bold' : '';

  return (
    <div className="table-row border-t-2 border-b-2 border-sw-lightest-gray py-10" role="row">
      <div className="table-cell text-xs text-sw-dark-blue pl-3 py-2 content-center px-3 " role="gridcell">
        <div className="flex items-center flex-row">
          <FontAwesomeIcon icon={icon} className="text-xl"/>
          <div className="text-xs text-sw-dark-blue pl-3">
            {title}
          </div>
        </div>
      </div>
      <div className={`table-cell text-xs text-sw-dark-blue py-2 ${boldChild}`} role="gridcell">
        {q.child || "N/A"}
      </div>
      <div className={`table-cell text-xs text-sw-dark-blue py-2 ${boldParent}`} role="gridcell">
        {q.parent || "N/A"}
      </div>
      <div className="table-cell py-2 flex pr-4" role="gridcell">
        <div className="flex justify-center">
          {discrepancyCell(q)}
        </div>
      </div>
    </div>
  )
}

export default BarrierRow