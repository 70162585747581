import React from 'react';
import DetailsQuestionTable from '../DetailsQuestionTable'
import AlertCard from '../../AlertCard'

// Hardcoded variables here that we use to determine severity of answers
// These affect the styling and will need to be updated if the rails portal
// logic is ever updated
const GAD_NEGATIVE_RESULT = 'Minimal or no'

const showSubheader = (details) => {
  if (!details || details.length === 0) return null;

  return (
    <p className="text-s font-bold text-sw-dark-blue pb-6">
      How often has your child been affected by each of the following symptoms in the past 2 weeks?
    </p>
  )
}

const ParentGad = ({ gad, gadDetails }) => {
  if (!gad) return null;

  // If GAD wasn't answered it can sometimes say "No answer given" which breaks the split on the comma
  // We'll just populate the score for GAD as 'N/A' in this case
  var gadScoreAndText = gad.split(',')
  if (gadScoreAndText.length === 1) gadScoreAndText = ['N/A', gadScoreAndText[0]]
  
  var gadColor = 'red'
  if (gad.includes(GAD_NEGATIVE_RESULT)) gadColor = 'green'
  if (gadScoreAndText[0] === 'N/A') gadColor = 'grey'

  const gadText = gadScoreAndText[1] ? gadScoreAndText[1].split('.').join("") : "No Answer Given"
  const gadExtraText = `Total Score = ${gadScoreAndText[0]}`

  return (
    <div className="mx-auto mb-8">
      <p className="text-xs font-bold text-center pt-8 border-t-2 border-sw-lightest-gray">
        PARENT REPORT
      </p>
      <p className="text-xl text-center font-bold text-sw-dark-blue pt-2">
        Anxiety (GAD-7)
      </p>
      <div className="w-full mx-auto flex justify-center">
        <div className="w-3/4 sm:w-1/3 flex justify-center mt-4 mb-8">
          <AlertCard
            color={gadColor}
            text={gadText}
            extraText={gadExtraText}
          />
        </div>
      </div>
      {showSubheader(gadDetails)}
      <DetailsQuestionTable questionType={gadDetails} />
    </div>
  )
}

export default ParentGad
