import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addToHistory,
  removeLastHistoryItem,
  setCurrentScreen
} from '../../actions/historyActions';
import {
  report,
  resetReport
} from '../../actions/questionActions';
import '../../assets/scss/_reports.scss';
import DetailedReport from './DetailedReport';
import { getId } from '../../util/helperFunctions'

class Reports extends Component {
  constructor(props) {
    super(props);
    const { historyList } = this.props;
    if (this.props.currentScreen === historyList[historyList.length - 2]) {
      this.props.removeLastHistoryItem(historyList);
    } else {
      this.props.addToHistory('/report', historyList);
    }
    this.state = { spinner: true };
  }

  componentWillMount() {
    this.props.resetReport();
    const token = getId();
    this.props.report(token);
  }

  componentWillUnmount() {
    this.props.resetReport()
  }
  
  render() {
    const { reportObject } = this.props;
    return (
      <div className="report-main-container">
        <div className="report-view">
          <div className="report-detail-container">
            <div className="width">
              <DetailedReport reportDetails={reportObject} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { history, question, login } = state;
  const {
    currentScreen,
    historyList
  } = history;
  const { token } = login;
  const { reportObject } = question;
  return {
    currentScreen,
    historyList,
    reportObject,
    token
  };
};

export default connect(mapStateToProps, {
  addToHistory,
  removeLastHistoryItem,
  report,
  setCurrentScreen,
  resetReport
})(Reports);
