import React from 'react';
import DetailsQuestionRow from './DetailsQuestionRow'

const DetailsQuestionTable = ({ questionType }) => {
  function mapQuestions(questions) {
    if (questions === null || questions.length === 0) {
      return <DetailsQuestionRow questionModule={null} question={null} answer={null} />
    };
    return questions.map((a, index) => {
      return (
        <div key={a.question} aria-describedby="caption" className="table w-full border-collapse table-fixed" role="grid">
          <div className="table-row-group">
            <DetailsQuestionRow
              questionModule={a.question_name}
              question={a.question}
              answer={a.answer_text}
              isEven={index % 2 === 0}
            />
          </div>
        </div>
      )
    })
  }

  return (
    <div aria-describedby="caption" className="table w-full border-collapse table-fixed" role="grid">
      <div className="table-row-group">
        {mapQuestions(questionType)}
      </div>
    </div>
  )
}

export default DetailsQuestionTable
