const INITIAL_STATE = {
  historyList: [],
  currentScreen: '/'
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case 'update_history':
    return {
      ...state,
      historyList: action.newHistoryList
    };
  case 'wipe_history_list':
    return {
      ...state,
      historyList: [],
      currentScreen: '/'
    };
  case 'set_current_screen':
    return {
      ...state,
      currentScreen: action.routeName
    };
  default:
    return state;
  }
};
