import React from 'react';
import '../../assets/scss/_common.scss';

const LoginComponent = ({ title, question, detail, button }) => (
  <div>
    <div className="blurred-background" />
    <div className="overlay-container">
      <div className="exit-view">
        <p className="exit-title">
          {title}
        </p>
      </div>
      <div style={{ flexDirection: 'column' }} className="overlay-question-view">
        <p className="question-text">
          {question}
        </p>
        <p className="question-text">
          {detail}
        </p>
      </div>
      {button}
    </div>
  </div>
);

export default LoginComponent;
