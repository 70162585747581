/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import useScrollPosition from '../useScrollPosition'

const MainHeader = React.forwardRef((props, ref) => {
  const { thresholdcrossed = () => null } = props

  useScrollPosition(
    ({ currPos, prevPos }) => {
      if (currPos.y === 210 || (currPos.y > 210 && prevPos.y < 210) || (currPos.y < 210 && prevPos.y > 210)) {
        thresholdcrossed()
      }
    }, [], ref
  )
  
  return (
    <a
      ref={ref}
      className="main-header flex container text-3xl font-black text-sw-dark-blue leading-10 my-6"
      name="summary"
    >
      Screening Wizard Results Summary
    </a>
  )
})

export default MainHeader
