import React from 'react';

const Counseling = () => {
  return (
    <div className="counseling-handout container pb-10">
      <div className="text-sw-dark-blue text-xl font-bold pb-7">
        If your provider prescribed antidepressants, here are some things you might want to know
      </div>
      <div aria-describedby="caption" className="table w-full border-collapse text-xs mb-4" role="grid">
        <div className="table-header-group" role="row">
          <div className="table-cell w-1/4 pr-4" role="columnheader">
          </div>
          <div className="table-cell w-3/4 " role="columnheader">
          </div>
        </div>
        <div className="table-row-group">
          <div className= "table-row mb-10" role="row">
            <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
              They will take some time to work!
            </div>
            <div className="table-cell text-sw-dark-blue pl-1 md:pl-2" role="gridcell">
              It may take 3-4 weeks for you to start feeling any help or change in your symptoms. Use this time to get used to remembering to take the medicine each day and getting used to any sidez eﬀects like nausea.
            </div>
          </div>
          <div className= "table-row" role="row">
            <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
              Remember to take it every day! (set a phone reminder)
            </div>
            <div className="py-3 table-cell text-sw-dark-blue pl-1 md:pl-2" role="gridcell">
              When you forget to take an antidepressant, you can experience symptoms that are not life-threatening but can make you feel sick. So sick to a routine to help you remember like putting the medicine by your toothbrush or something you do daily.
            </div>
          </div>
          <div className= "table-row" role="row">
            <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
              Talk to your provider before stopping your medicine.
            </div>
            <div className="py-3 table-cell text-sw-dark-blue pl-1 md:pl-2" role="gridcell">
              Please call your provider if you want to stop your medicine. Antidepressants have to be stopped in a special way and we don’t want you to feel sick. 
            </div>
          </div>
          <div className= "table-row" role="row">
            <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
              This is at least a year-long commitment.
            </div>
            <div className="py-3 table-cell text-sw-dark-blue pl-1 md:pl-2" role="gridcell">
              If you get better with an antidepressant, studies show you should take it about one year or your symptoms may get worse if you stopit early.
            </div>
          </div>
          <div className= "table-row" role="row">
            <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
              Call if you think you are experiencing a side eﬀect you don’t like.
            </div>
            <div className="py-3 table-cell text-sw-dark-blue pl-1 md:pl-2" role="gridcell">
              Some side eﬀects are to be expected like nausea and get better with time, but others might be ones you don’t think you want to deal with. Please call your provider if that occurs. 
            </div>
          </div>
        </div>
      </div>



    <div className="text-sw-dark-blue text-xl font-bold py-4">
        Some people have beliefs about medications used for depression. Here is a list of myths and facts:
    </div>
    <div aria-describedby="caption" className="table w-full border-collapse text-xs mb-4" role="grid">
      <div className="table-header-group" role="row">
        <div className="table-cell py-3 text-sw-dark-blue font-bold w-1/4" role="columnheader">
          MYTH
        </div>
        <div className="table-cell py-3 pl-2 text-sw-dark-blue font-bold w-3/4" role="columnheader">
          FACT
        </div>
      </div>
      <div className="table-row-group">
        <div className= "table-row mb-10 flex space-x-4" role="row">
          <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
            Taking a medication is not going to solve the problems in my life.
          </div>
          <div className="table-cell text-sw-dark-blue pl-1 md:pl-2" role="gridcell">
            That’s right. Your choice to start a medication is one of many steps you may take to help with your depression.
          </div>
        </div>
        <div className= "table-row" role="row">
          <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
            I will get a terrible side eﬀect.
            </div>
          <div className="py-3 table-cell text-sw-dark-blue pl-1 md:pl-2" role="gridcell">
            You may get a side eﬀect, but most often, these are mild and go away with time. The ﬁrst few weeks you try an antidepressant are a “trial run” to see if you need to stop the medication due to side eﬀects. No one will force you to keep taking the medication if you experience a side eﬀect you don’t like. There are other medications you can try which may not have the same side eﬀect.
          </div>
        </div>
        <div className= "table-row" role="row">
          <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
            These medications are addictive.
          </div>
          <div className="py-3 table-cell text-sw-dark-blue pl-1 md:pl-2" role="gridcell">
            Antidepressants are not addictive. You do not experience a ‘high’ from taking them. They are a type of medication that you have to decrease slowly so that you do not get side eﬀects. Talk to a provider before stopping the antidepressant. Some people have a harder time stopping an antidepressant because they feel more anxious or depressed but most people do not. 
          </div>
        </div>
        <div className= "table-row" role="row">
          <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
            If I need medication, that means I am a failure.
          </div>
          <div className="py-3 table-cell text-sw-dark-blue pl-1 md:pl-2" role="gridcell">
            For some people, medication can help treat their depression. Depression is a medical problem. Just like asthma is not someone’s fault, and they may need mediction to treat it.
          </div>
        </div>
        <div className= "table-row" role="row">
          <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
            Antidepressants would change my personality.
          </div>
          <div className="py-3 table-cell text-sw-dark-blue pl-1 md:pl-2" role="gridcell">
            Some people experience a more muted mood when they take antidepressants. If this happens to you and is something you do not like, trying a diﬀerent antidepressant may work better. 
          </div>
        </div>
        <div className= "table-row" role="row">
          <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
            My family wouldn’t want me using these types of medications.
          </div>
          <div className="py-3 table-cell text-sw-dark-blue pl-1 md:pl-2" role="gridcell">
            Some family members may not understand your depression, but it also may be just as likely that you have family members who take antidepressants, that you do not know about.
          </div>
        </div>
        <div className= "table-row" role="row">
          <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
            Only really messed up people need medications.
          </div>
          <div className="py-3 table-cell text-sw-dark-blue pl-1 md:pl-2" role="gridcell">
            People who decide to take medication like an antidepressant are making a decision about improving their mental health. This is a step someone who is wanting to reach their goals in life is wanting to take. It is not giving up. It does not mean you are messed up. It is a strength.
          </div>
        </div>
      </div>
    </div>

 
    <div className="text-sw-dark-blue text-xl font-bold py-4">
      Common Antidepressant Side Eﬀects and What to Do:
    </div>
      <div aria-describedby="caption" className="table w-full border-collapse text-xs mb-4" role="grid">
      <div className="table-header-group" role="row">
        <div className="table-cell w-1/4 pr-4" role="columnheader">
        </div>
        <div className="table-cell w-3/4 " role="columnheader">
        </div>
      </div>
        <div className= "table-row" role="row">
          <div className="py-2 table-cell font-bold" role="gridcell">
            Dry Mouth
          </div>
          <div className="table-cell text-sw-dark-blue" role="gridcell">
            Drink water. Try sugarless gum. 
            </div>
        </div>
        <div className="table-row" role="row">
          <div className="table-cell font-bold" role="gridcell">
            Nausea
            </div>
          <div className="py-2 table-cell text-sw-dark-blue" role="gridcell">
            Take your medicine with food.
            </div>
        </div>
        <div className="table-row" role="row">
          <div className="table-cell font-bold" role="gridcell">
            Constipation
            </div>
          <div className="py-2 table-cell text-sw-dark-blue" role="gridcell">
            Drink water. Try a capful of Miralax daily.
            </div>
        </div>
        <div className="table-row" role="row">
          <div className="table-cell font-bold" role="gridcell">
            Drowsiness
            </div>
          <div className="py-2 table-cell text-sw-dark-blue" role="gridcell">
            Try taking your medicine at night.
            </div>
        </div>
        <div className="table-row" role="row">
          <div className="table-cell font-bold" role="gridcell">
            Wakefulness 
            </div>
          <div className="py-2 table-cell text-sw-dark-blue" role="gridcell">
            Try taking your medicine early.
            </div>
        </div>
        <div className="table-row" role="row">
          <div className="table-cell pr-2 font-bold" role="gridcell">
            Feeling overenergized
            </div>
          <div className="py-2 table-cell text-sw-dark-blue" role="gridcell">
            This should start to get better in 3-5 days. If not let your provider know.
            </div>
        </div>
        <div className="table-row" role="row">
          <div className="table-cell font-bold" role="gridcell">
            Headache
            </div>
          <div className="pb-4 table-cell text-sw-dark-blue" role="gridcell">
            This should start to get better in 3-7 days. If not let your provider know.
            </div>
        </div>
        </div>
        <div className="text-xs py-3">
          Some other side eﬀects include weight changes and sexual side eﬀects. 
        </div>
        <p className="text-xs text-sw-dark-blue">
          <span className="font-bold">
            Rare but serious side eﬀects: &nbsp;
          </span>
            It is rare to experiencing increased thoughts of wanting to die(studies show in depressed people taking medicine, it was 4/100 and in those not taking medicine, it was 2/100) but if this happens,contact crisis services right away and call your provider to follow-up. If you experience mania (very elevated mood, hard to slow down, racing thoughts, decreased need for sleep, pressured speech, buying sprees or other painful but pleasurable activity), call your provider to let them know asap.
        </p>
    </div>
  )
}

export default Counseling;