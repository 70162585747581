import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import '../assets/scss/_common.scss'

const Spinner = () => (
  <div style={{ marginTop: '20px' }}>
    <FontAwesomeIcon icon={faSpinner} className="spinner" />
  </div>
);

export default Spinner;
