import React from 'react';
import '../../../assets/scss/_reports.scss';
import SummaryTableHeader from './SummaryTableHeader';
import AnxietyRow from './TableRows/AnxietyRow';
import ConcernsRow from './TableRows/ConcernsRow';
import DepressionRow from './TableRows/DepressionRow';
import ManiaRow from './TableRows/ManiaRow';
import SubstanceUseRow from './TableRows/SubstanceUseRow';
import SuicidalityRow from './TableRows/SuicidalityRow';

const SummaryTable = ({ advanceReport }) => {
  return (
    <div className="summary-table container mb-2">
      <div aria-describedby="caption" className="table w-full border-collapse" role="grid">
        <SummaryTableHeader />
        <div className="table-row-group py-2">
          <SuicidalityRow advanceReport={advanceReport} />
          <DepressionRow advanceReport={advanceReport} />
          <AnxietyRow advanceReport={advanceReport} />
          <ManiaRow advanceReport={advanceReport} />
          <SubstanceUseRow advanceReport={advanceReport} />
          <ConcernsRow advanceReport={advanceReport} />
        </div>
      </div>
    </div>
  )
}

export default SummaryTable;