/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import useScrollPosition from '../useScrollPosition'

const BasicItemHeader = React.forwardRef((props, ref) => {
  const { thresholdcrossed = () => null } = props

  useScrollPosition(
    ({ currPos, prevPos }) => {
      if (currPos.y === 210 || (currPos.y > 210 && prevPos.y < 210) || (currPos.y < 210 && prevPos.y > 210)) {
        thresholdcrossed()
      }
    }, [], ref
  )
  
  return (
    <div className="container">
      <div className="basic-item-header text-3xl font-black text-sw-dark-blue leading-10 py-8 border-t-8 border-sw-dark-blue print-padding">
        <a
          ref={ref}
          className=""
          name="item-level-report"
        >
          Screening Wizard Item Level Report:&nbsp;<span className="underline">Adolescent</span>
        </a>
      </div>
    </div>
  )
})

export default BasicItemHeader
