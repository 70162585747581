import {
  faDollarSign,
  faCar,
  faFrown,
  faClock,
  faEyeSlash,
  faBriefcaseMedical
} from '@fortawesome/free-solid-svg-icons'

export const CHILD_MAP = {
  "I could not afford to see a therapist even if I wanted to.": 0,
  "I would have trouble finding transportation to get to therapy appointments.": 1,
  "Even if I wanted to, I wouldn't have time to see a therapist.": 2,
  "If I had a problem and told a therapist, the therapist would not keep it a secret.": 3,
  "Even if I had a problem, I'd be too embarrassed to talk to a therapist about it.": 4
}

export const PARENT_MAP = {
  "I could not afford to get professional help for my child's problem(s) even if I wanted to.": 0,
  "I would have trouble finding transportation for my child to get to therapy appointments.": 1,
  "Even if I wanted to, I wouldn't have time to get professional help for my child's problem(s).": 2,
  "If I got professional help for my child's problem, the problem would not be kept secret.": 3,
  "Even if I feel my child had a problem, I'd be too embarrassed to get professional help for it.": 4
}

export const DISC_MAP = {
  'cost': {
    title: "Cost",
    icon: faDollarSign
  },
  'transportation': {
    title: "Transportation",
    icon: faCar
  },
  'embarrassed': {
    title: "Stigma",
    icon: faFrown
  },
  'time': {
    title: "Time",
    icon: faClock
  },
  'secret': {
    title: "Confidentiality",
    icon: faEyeSlash
  },
  'medication': {
    title: 'Medication',
    icon: faBriefcaseMedical
  }
}

export const ANSWER_MAP = {
  "not worried at all": "Disagree",
  "somewhat worried": "Slightly Agree",
  "very worried": "Strongly Agree"
}