import React from 'react';
import '../../assets/scss/_survey.scss';

const Question = (props) => (
  <div className="question-view">
    <p className="question-view-text">
      {props.question}
    </p>
  </div>
);

export default Question;
