const INITIAL_STATE = {
  uid: '',
  client: '',
  token: '',
  password: '',
  loggedIn: false,
  message: '',
  surveyToken: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case 'log_in':
    return {
      ...state,
      uid: action.uid,
      client: action.client,
      token: action.token,
      password: action.password,
      loggedIn: true
    };
  case 'show_login_error':
    return {
      ...state,
      message: action.message
    };
  case 'reset_login_error':
    return {
      ...state,
      message: ''
    };
  case 'reset_log_in':
    return {
      ...state,
      loggedIn: false
    };
  case 'save_token':
    return {
      ...state,
      surveyToken: action.surveyToken
    };
  default:
    return state;
  }
};
