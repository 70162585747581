import React from 'react';
import '../assets/scss/_common.scss';

const CommonButton = (props) => {
  if (props.disabled === false) {
    return (
      <div className={props.extraPrimaryStyle}>
        <button
          className={`${props.extraBtnStyle} button`}
          type="button"
          disabled={props.disabled}
          onClick={() => props.onClick()}
        >
          <p
            className={`text ${props.extraPrimaryTextStyle}`}
          >
            {props.buttonText}
          </p>
        </button>
      </div>
    )
  }
  return (
    <div className={props.extraPrimaryStyle}>
      <button
        type="button"
        className={`${props.extraDisabledBtnStyle} button disabled`}
        disabled={props.disabled}
        onClick={() => props.onClick()}
      >
        <p
          className={`text-disabled text ${props.extraPrimaryTextStyle}`}
        >
          {props.buttonText}
        </p>
      </button>
    </div>
  );
}

export default CommonButton;
