import React from 'react';
import '../../assets/scss/_survey.scss';

const Check = (props) => (
  <div className="check-view">
    <input
      type="checkbox"
      disabled={props.disabled}
      id={props.id}
      ischecked={props.checked.toString()}
      onClick={() => props.setAnswer()}
    />
    <label htmlFor={props.id} className="check-label">{props.label}</label>
  </div>
);

export default Check;
