import React from 'react';
import AlertCard from '../../AlertCard'

// Hardcoded variables here that we use to determine severity of answers
// These affect the styling and will need to be updated if the rails portal
// logic is ever updated
const EMOTIONAL_POSITIVE_RESULT = 'Yes'

const ParentEmotional = ({ general }) => {
  // Here we detail all the copy added in the Alert Cards below. It's easier to see
  // the logic for determining the text, color and extra text here than it is in the
  // html structure

  const emotionalIsPositive = general.includes(EMOTIONAL_POSITIVE_RESULT)
  const emotionalColor = emotionalIsPositive ? 'red' : 'green'
  const emotionalText = emotionalIsPositive ? 'Yes' : 'No'

  return (
    <div className="mx-auto mb-8">
      <p className="text-xs font-bold text-center pt-8 border-t-2 border-sw-lightest-gray">
        PARENT REPORT
      </p>
      <p className="text-xl text-center font-bold text-sw-dark-blue pt-2">
        Emotional Concern
      </p>
      <div className="w-full mx-auto flex justify-center">
        <div className="w-3/4 sm:w-1/3 flex justify-center mt-4 mb-8">
          <AlertCard
            color={emotionalColor}
            text={emotionalText}
          />
        </div>
      </div>
    </div>
  )
}

export default ParentEmotional
