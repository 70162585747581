import React from 'react';
import { get } from '../../../util/helperFunctions'
import ChildGad from './ChildItemLevelSections/ChildGad';
import ChildPhq9 from './ChildItemLevelSections/ChildPhq9';
import ChildCatSS from './ChildItemLevelSections/ChildCatSS';
import ChildSbirt from './ChildItemLevelSections/ChildSbirt';
import ChildMania from './ChildItemLevelSections/ChildMania';
import ChildCrafft from './ChildItemLevelSections/ChildCrafft';

const ItemLevelReport = ({
  reportDetails,
  phq9Details,
  crafftDetails,
  sbirtDetails,
  catDetails,
  cmrsDetails,
  gadDetails
}) => {

  if (reportDetails.questions.child.length === 0) return null

  // The get() function allows us to dig through the object for an answer and default to an empty string.
  // Per the above TODO, we might want to default to something else like null and allow smaller components
  // to run a check and render some default information if necessary
  const cat = get(['results', 'child', 'cat'], reportDetails, '')
  const phq9 = get(['results', 'child', 'phq9'], reportDetails, '')
  const gad = get(['results', 'child', 'gad'], reportDetails, '')
  const cmrs = get(['results', 'child', 'cmrs'], reportDetails, '')
  const sbirt = get(['results', 'child', 'sbirt'], reportDetails, '')
  const mania = get(['results', 'child', 'mania'], reportDetails, '')

  return (
    <div className="child-item-level-report container">
      {/* CAT */}
      <ChildCatSS cat={cat} catDetails={catDetails} />

      {/* PHQ9 */}
      <ChildPhq9 phq9={phq9} phq9Details={phq9Details} />

      {/* GAD */}
      <ChildGad gad={gad} gadDetails={gadDetails} />

      {/* MANIA */}
      <ChildMania
        reportDetails={reportDetails}
        cmrsDetails={cmrsDetails}
        cmrs={cmrs}
        mania={mania}
      />

      {/* SUBSTANCE USE */}
      <ChildSbirt sbirt={sbirt} sbirtDetails={sbirtDetails} />

      {/* CRAFFT */}
      <ChildCrafft reportDetails={reportDetails} crafftDetails={crafftDetails} />
    </div>
  )
}

export default ItemLevelReport
