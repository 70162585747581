import React from 'react';
import '../../assets/scss/_common.scss';

const MemeComponent = (props) => (
  <div>
    <div className="blurred-background" />
    <div className="overlay-container" style={{ backgroundColor: 'transparent' }}>
      <div style={{ position: 'relative' }}>
        <img src={`/ScreeningWizardMeme${props.image}.jpg`} />
        <button
          style={{ position: 'absolute', top: 1, right: 15, fontSize: 30, fontWeight: 'bold' }}
          onClick={() => props.returnToQuestion()}
        >
          X
        </button>
      </div>
    </div>
  </div>
);

export default MemeComponent;
