import React from 'react';
import BarrierRow from './BarrierRow';
import {
  CHILD_MAP,
  PARENT_MAP,
  DISC_MAP,
  ANSWER_MAP
} from './barriersMappings'

const Barriers = ({ advanceReport }) => {
  const discrepancyData = (child, parent) => {
    const discrepancies = [
      { type: 'cost', child: '', parent: '' },
      { type: 'transportation', child: '', parent: '' },
      { type: 'time', child: '', parent: '' },
      { type: 'secret', child: '', parent: '' },
      { type: 'embarrassed', child: '', parent: '' },
      { type: 'medication', child: '', parent: '' }
    ];
    if (child.length > 0) {
      child.forEach((q) => {
        if (q.question.startsWith('bash')) {
          Object.defineProperty(discrepancies[CHILD_MAP[q.question_text]], 'child', { value: q.answer_text.trim() })
        }
        if (q.question.startsWith('ams')) {
          Object.defineProperty(discrepancies[5], 'child', { value: ANSWER_MAP[q.answer_text.trim().toLowerCase()] })
        }
      })
    }
    if (parent.length > 0) {
      parent.forEach((q) => {
        if (q.question_name.startsWith('bash')) {
          Object.defineProperty(discrepancies[PARENT_MAP[q.question_text]], 'parent', { value: q.answer_text.trim() })
        }
        if (q.question.startsWith('ams')) {
          Object.defineProperty(discrepancies[5], 'parent', { value: ANSWER_MAP[q.answer_text.trim().toLowerCase()] })
        }
      })
    }
    return discrepancies;
  }

  const discrepancyMap = (child, parent) => {
    const newData = discrepancyData(child, parent);
    return newData.map((q) => {
      const item = DISC_MAP[q.type];
      return (
        <BarrierRow
          key={item.title}
          q={q}
          title={item.title}
          icon={item.icon}
        />
      )
    });
  }

  return (
    <div className="barriers-to-treatment container">
      <div aria-describedby="caption" className="table w-full border-collapse my-8 print-space" role="grid">
        <div className="table-header-group barriers-table-header text-xs text-sw-dark-blue font-bold bg-sw-light-blue pb-2 " role="row">
          <div className="table-cell pl-3 py-2 pr-4" role="columnheader">
            BARRIERS TO TREATMENT
          </div>
          <div className="table-cell py-2 pr-4 " role="columnheader">
            ADOLESCENT
          </div>
          <div className="table-cell py-2 pr-4" role="columnheader">
            PARENT
          </div>
          <div className="table-cell py-2 pr-4 flex" role="columnheader">
            <div className="flex justify-center">
            DISCREPANCY
            </div>
          </div>
        </div>
        <div className="table-row-group py-2">
          {discrepancyMap(advanceReport.questions.child, advanceReport.questions.guardian)}
        </div>
      </div>
    </div>
  )
}

export default Barriers
