import React from 'react';
import '../../assets/scss/_common.scss';

const list = (item) => (`\u2022 ${item}`);

const Bullets = ({ question }) => (
  question.map((item) => (
    <div key={item.bold}>
      <p
        className="question-text"
      >
        {list(item.para)}
        <strong>{item.bold}</strong>
        {item.secondPara}
      </p>
    </div>
  ))
)

const BulletsComponent = ({ question, button }) => (
  <div>
    <div className="blurred-background" />
    <div className="overlay-container">
      <div className="bullet-list">
        <Bullets question={question} />
      </div>
      {button}
    </div>
  </div>
);

export default BulletsComponent;
