import React from 'react';
import { suicidalityNotes } from '../tableNotes'

const catssScore = (advanceReport) => {
  if (!advanceReport.results.child.cat) 
    return (
      "N/A"
    )

  const score = advanceReport.results.child.cat_score
  const text = advanceReport.results.child.cat

  return (
    <div>
      CAT-SS: {score}, {text}
    </div>
  )
}

const showScore = (score) => {
  if (!score) return null

  return (
    <div>
      PHQ-9 = {score.answer_text}
      <br />
      Thoughts of harm&nbsp;
    </div>
  )
}

const SuicidalityRow = ({ advanceReport }) => {
  const { guardian } = advanceReport.results

  const phq9ScoreChild = advanceReport.questions.child.find((q) => q.question.startsWith('phq_9: 9'))
  const phq9ScoreGuardian = advanceReport.questions.guardian.find((q) => q.question.startsWith('phq_9: 9'))

  return (
    <div className="table-row border-b-2 border-t-2 border-sw-lightest-gray" role="row">
      <div className="table-cell font-bold text-lg text-sw-dark-blue pr-14 py-3" role="rowheader">
        Suicidality
      </div>
      <div className="table-cell text-xs text-sw-dark-blue font-bold py-3 pr-3 align-top leading-loose" role="gridcell">
        {catssScore(advanceReport)}
        {showScore(phq9ScoreChild)}
      </div>
      <div className="table-cell text-xs text-sw-dark-blue font-bold py-3 pr-3 align-top leading-loose" role="gridcell">
        {guardian.cat || ""} {/* I think the guardian.cat will ALWAYS be null, but I'm not positive */}
        {showScore(phq9ScoreGuardian) || "PHQ-9 = N/A"}
      </div>
      <div className="table-cell align-top" role="gridcell">
        {suicidalityNotes(advanceReport)}
      </div>
    </div>
  )
}

export default SuicidalityRow;