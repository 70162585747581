import React from 'react';
import DetailsQuestionTable from '../DetailsQuestionTable'
import AlertCard from '../../AlertCard'

// Hardcoded variables here that we use to determine severity of answers
// These affect the styling and will need to be updated if the rails portal
// logic is ever updated
const MANIA_WARNING_RESULT = 'Concern'
const MANIA_HISTORY_RESULT = 'Positive'

const maniaAnswer = (questions, question_name) => (
  questions.filter(q => q.question_name === question_name).map(q => q.answer_text).join(", ")
)

const ChildMania = ({
  reportDetails,
  cmrsDetails,
  cmrs,
  mania
}) => {
  var maniaText = 'N/A';
  var maniaColor = 'grey';
  if (cmrs) {
    maniaText = cmrs.split('.').join("");
    maniaColor = maniaText === MANIA_WARNING_RESULT ? 'red' : 'green'
  }

  const maniaHistoryIsPositive = mania.includes(MANIA_HISTORY_RESULT)
  var maniaHistoryColor = maniaHistoryIsPositive ? 'red' : 'green'

  var maniaHistoryText = null
  if (mania === 'No answer given' || !mania) {
    maniaHistoryText = 'N/A'
    maniaHistoryColor = 'grey'
  }
  if (!maniaHistoryText && maniaHistoryIsPositive) maniaHistoryText = 'Positive'
  if (!maniaHistoryText && !maniaHistoryIsPositive) maniaHistoryText = 'None'

  return (
    <div className="mx-auto mb-8 border-t-2 border-sw-lightest-gray">
      <div className="flex flex-col sm:flex-row">
        <div className="w-full sm:w-1/2">
          <p className="text-xs font-bold text-center pt-8 ">
            ADOLESCENT REPORT
          </p>
          <p className="text-xl text-center font-bold text-sw-dark-blue pt-2">
            Mania (CMRS)
          </p>
          <div className="w-full mx-auto flex justify-center">
            <div className="w-3/4 md:w-1/2 mt-4 mb-8">
              <AlertCard
                color={maniaColor}
                text={maniaText}
              />
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/2">
          <p className="text-xs font-bold text-center sm:pt-8">
            ADOLESCENT REPORT
          </p>
          <p className="text-xl text-center font-bold text-sw-dark-blue pt-2">
            Family History of Mania: {maniaAnswer(reportDetails.questions.child, "mania_history_1")}
          </p>
          <div className="w-full mx-auto flex justify-center">
            <div className="w-3/4 md:w-1/2 mt-4 mb-8">
              <AlertCard
                color={maniaHistoryColor}
                text={maniaHistoryText}
              />
            </div>
          </div>
        </div>
      </div>
      <DetailsQuestionTable questionType={cmrsDetails} />
    </div>
  )
}

export default ChildMania
