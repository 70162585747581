import React from 'react';

const PatientHandout = () => {
  return (
    <div className="patient-handout contains-full-screen-bottom-border">
      <div className="container mb-8">
        <div className="text-sw-dark-blue text-xl font-bold pb-4">
          Today your provider may have discussed your mental health with you.
          <span className="block">
            Listed below is some information and resources that might help:
          </span>
        </div>
        <div aria-describedby="caption" className="table w-full border-collapse text-xs mb-4" role="grid">
          <div className="table-header-group" role="row">
            <div className="table-cell w-1/4 pr-4" role="columnheader">
            </div>
            <div className="table-cell w-3/4 " role="columnheader">
            </div>
          </div>
          <div className="table-row-group">
            <div className="table-row mb-10" role="row">
              <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
                What is depression?
              </div>
              <div className="py-3 table-cell text-sw-dark-blue pl-1 md:pl-2" role="gridcell">
                An intense mood of sadness, empIness,hopelessness, or feeling irritated, even at little things.
                <span className="block">
                  <a
                    href="www.nimh.nih.gov/health/publications/teen-depression/index.shtml"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sw-primary-blue"
                  >
                    www.nimh.nih.gov/health/publications/teen-depression/index.shtml
                  </a>
                </span>
              </div>
            </div>
            <div className="table-row mb-10" role="row">
              <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
                What is anxiety?
              </div>
              <div className="py-3 table-cell text-sw-dark-blue pl-1 md:pl-2" role="gridcell">
                Frequent worry that is diﬃcult to control which causes distress or trouble functioning.
                <span className="block">
                  <a
                    href="http://anxietycanada.com/learn-about-anxiety/anxiety-in-youth/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sw-primary-blue"
                  >
                    http://anxietycanada.com/learn-about-anxiety/anxiety-in-youth/
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="text-sw-dark-blue text-xl font-bold py-4">
          Treatments that can help...
        </div>
        <div aria-describedby="caption" className="table w-full border-collapse text-xs mb-4" role="grid">
          <div className="table-header-group" role="row">
            <div className="table-cell w-1/4 pr-4" role="columnheader">
            </div>
            <div className="table-cell w-3/4 " role="columnheader">
            </div>
          </div>
          <div className="table-row-group">
            <div className="table-row" role="row">
              <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
                Taking care of your body!
              </div>
              <div className="py-3 table-cell text-sw-dark-blue pl-1 md:pl-2" role="gridcell">
                Get enough sleep:&nbsp;
                <a
                  href="sleepeducation.org/esssentials-in-sleep/healthy-sleep-habits"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sw-primary-blue"
                >
                  sleepeducation.org/esssentials-in-sleep/healthy-sleep-habits
                </a>
                , Eat healthy:&nbsp;
                <a
                  href="http://www.choosemyplate.gov/browse-by-audience/view-all-audiences/students/teens"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sw-primary-blue"
                >
                  www.choosemyplate.gov/browse-by-audience/view-all-audiences/students/teens
                </a>
                , Exercise: &nbsp;
                <a
                  href="safeteens.org/nutrition-exercise/exercise-ﬁtness"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sw-primary-blue"
                >
                  safeteens.org/nutrition-exercise/exercise-ﬁtness
                </a>
                , Manage technology use, light therapy and mindfulness:&nbsp;
                <a
                  href="mindfulnessforteens.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sw-primary-blue"
                >
                  mindfulnessforteens.com
                </a>
                &nbsp;See your PCP if you don’t feel better!
              </div>
            </div>
            <div className="table-row" role="row">
              <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
                Talk Therapy
              </div>
              <div className="py-3 table-cell pl-1 md:pl-2" role="gridcell">
                When you forget to take an antidepressant, you can experience symptoms that are not life-threatening but can make you feel sick.
                So stick to a routine to help you remember like putting the medicine by your toothbrush or something you do daily.
              </div>
            </div>

            <div className="table-row" role="row">
              <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
                Medication
              </div>
              <div className="py-3 table-cell pl-1 md:pl-2" role="gridcell">
                Antidepressants are daily medications which can be used to treat depression and anxiety. Although they are called antidepressants they have actually been found to be more effective for anxiety.  &nbsp;
                <a
                  href="http://www.nami.org/getattachment/Press-Media/Press-Releases/2010/NAMI-Releases-Family-Guide-on-Adolescent-Depressio/FamilyGuide2010.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sw-primary-blue"
                >
                  www.nami.org/getattachment/Press-Media/Press-Releases/2010/NAMI-Releases-Family-Guide-on-Adolescent-Depressio/FamilyGuide2010.pdf
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="text-sw-dark-blue text-xl font-bold pb-4">
          If your provider has additional concerns…
        </div>
        <div aria-describedby="caption" className="table w-full border-collapse text-xs mb-4" role="grid">
          <div className="table-header-group" role="row">
            <div className="table-cell w-1/4 pr-4" role="columnheader">
            </div>
            <div className="table-cell w-3/4 " role="columnheader">
            </div>
          </div>
          <div className="table-row-group">
            <div className="table-row" role="row">
              <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
                Mania
              </div>
              <div className="py-3 table-cell pl-1 md:pl-2" role="gridcell">
                This is a mood disorder whensomeone feels very elevated, energetic, with decreased need for sleep. If concerned, your provider may refer you to the Child and Adolescent Bipolar Spectrum Services (CABS) Clinic. To initiate an Outpatient assessment at CABS, please call
                <span className="text-sw-primary-blue">
                  &nbsp; 412-246-5238&nbsp;
                </span>
                or
                <span className="text-sw-primary-blue">
                  &nbsp;1-877-851-CABS.&nbsp;
                </span>
                <a
                  href="http://www.pediatricbipolar.pitt.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sw-primary-blue"
                >
                  www.pediatricbipolar.pitt.edu/
                </a>
              </div>
            </div>
            <div className="table-row" role="row">
              <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
                Severe Depression
              </div>
              <div className="py-3 table-cell pl-1 md:pl-2" role="gridcell">
                More severe depression can beneﬁt from intensive therapy and medication. If concerned, your provider may refer you to the Services for Teens at Risk (STAR) Clinic. Call
                <span className="text-sw-primary-blue">
                  &nbsp; 412.246.5619 &nbsp;
                </span>
                to get more information.  &nbsp;
                <a
                  href="www.starcenter.pitt.edu"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sw-primary-blue"
                >
                  www.starcenter.pitt.edu
                </a>
              </div>
            </div>
          </div>
          <div className="table-row" role="row">
            <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
              Suicidality
            </div>
            <div className="py-3 table-cell pl-1 md:pl-2" role="gridcell">
              If you feel suicidal, it is important to make a safety plan. Make sure to make your environment safe and that any ﬁrearms are secured properly (go to &nbsp;
              <a
                href="projectchildsafe.org"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sw-primary-blue"
              >
                projectchildsafe.org
              </a>
              &nbsp; to ﬁnd out how and order a free lock). For crisis, in Pittsburgh, Allegheny County, that number (Resolve Crisis Services) is
              <span className="text-sw-primary-blue">
                &nbsp;1.888.796.8226. &nbsp;
              </span>
              If you live somewhere else, you can call the National Suicide Prevention Lifeline at
              <span className="text-sw-primary-blue">
                &nbsp;1.800.273.8255 &nbsp;
              </span>
              and they will connect you with your local crisis center. Otherwise you can call 911. Other resources include: &nbsp;
              <a
                href="crisischat.org"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sw-primary-blue"
              >
                crisischat.org
              </a>
              , an online crisis resource from the National Suicide Prevention Lifeline, &nbsp;
              <a
                href="imalive.org"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sw-primary-blue"
              >
                imalive.org
              </a>
              , and crisis textline which can also be contacted by texting “START” to
              <span className="text-sw-primary-blue">
                &nbsp;741-741
              </span>
            </div>
          </div>
          <div className="table-row" role="row">
            <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
              Substance Use
            </div>
            <div className="py-3 table-cell pl-1 md:pl-2" role="gridcell">
              Find treatment facilities for substance abuse/addiction and/or mental health problems.  &nbsp;
              <a
                href="https:ﬁndtreatment.samhsa.gov/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sw-primary-blue"
              >
                https:ﬁndtreatment.samhsa.gov/
              </a>
              &nbsp; Find physicians authorized to treat opioid dependency with buprenorphine by state:  &nbsp;
              <a
                href="http://www.samhsa.gov/medication-assisted-treatment-physician-locator"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sw-primary-blue"
              >
                http://www.samhsa.gov/medication-assistedphysician-program-datatreatment-physician-locator
              </a>
              &nbsp; Search opioid treatment programs by state:  &nbsp;
              <a
                href="https://dpt2.samhsa.gov/treatment"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sw-primary-blue"
              >
                https://dpt2.samhsa.gov/treatment
              </a>
            </div>
          </div>
          <div className="table-row" role="row">
            <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
              Tobacco, Nicotine Use
            </div>
            <div className="py-3 table-cell pl-1 md:pl-2" role="gridcell">
              E-cigarettes have nicotine too. Teens who start using e-cigarettes are more likely to move onto actual cigarettes. There may be harmful eﬀects from vapor as well and it can contain cancer-causing chemicals. If you want to quit, there are many tools that can help. Visit &nbsp;
              <a
                href="teen.smokefree.gov"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sw-primary-blue"
              >
                teen.smokefree.gov
              </a>
              &nbsp;  to sign up for texts, an app, or speak to a smoking cessation expert. Talk to your doctor if you are interested in nicotine replacement therapy.
            </div>
          </div>
        </div>

        <div className="text-sw-dark-blue text-xl font-bold pb-4">
          Many people have worries about getting help for their mental health…
        </div>
        <div aria-describedby="caption" className="table w-full border-collapse text-xs mb-4" role="grid">
          <div className="table-header-group" role="row">
            <div className="table-cell w-1/4 pr-4" role="columnheader">
            </div>
            <div className="table-cell w-3/4 " role="columnheader">
            </div>
          </div>
          <div className="table-row-group">
            <div className="table-row" role="row">
              <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
                Cost
              </div>
              <div className="py-3 table-cell pl-1 md:pl-2" role="gridcell">
                If you think you cannot aﬀord treatment, let your provider know because they may be aware of other resources – like free care – or other types of health coverage – like Medicaid - that may be available to you. Find out more information here:  &nbsp;
                <a
                  href="http://www.mentalhealthamerica.net/paying-care"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sw-primary-blue"
                >
                  www.mentalhealthamerica.net/paying-care
                </a>
              </div>
            </div>
            <div className="table-row" role="row">
              <div className="table-cell font-bold pr-1 md:pr-2" role="gridcell">
                Confidentiality &nbsp;
              </div>
              <div className="py-3 table-cell pl-1 md:pl-2" role="gridcell">
                There are laws to protect your confidentiality. In Pennsylvania, if you are age 14 or older you have a right to consent (or give your permission for) mental health treatment without your parent’s consent and without your parent having to be noIﬁed. At any age, you can provide your consent for drug and alcohol treatment. Learn more:  &nbsp;
                <a
                  href="https://www.aclupa.org/en/reference-card-minors-access-confidential-health-care-pennsylvania"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sw-primary-blue"
                >
                  https://www.aclupa.org/en/reference-card-minors-access-confidential-health-care-pennsylvania
                </a>
                &nbsp; and here:  &nbsp;
                <a
                  href="teenhealthlaw.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sw-primary-blue"
                >
                  teenhealthlaw.org
                </a>
              </div>
            </div>
            <div className="table-row" role="row">
              <div className="py-3 table-cell font-bold pr-1 md:pr-2" role="gridcell">
                Stigma
              </div>
              <div className="table-cell pl-1 md:pl-2" role="gridcell">
                Some people feel shame about the mental health symptoms they have. Your healthcare provider and therapist have heard a lot of people’s private information – and that they think about mental illness symptoms the same way as they might think about high blood pressure. It is something that many people have, can have a big eﬀect on their lives, but many treatments can help.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="handouts-end w-screen bg-sw-dark-blue h-20 mb-8">
      </div>
    </div>
  )
}
export default PatientHandout;