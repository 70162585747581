import React from 'react';
import {
  childReadiness,
  guardianReadiness,
  childPreference,
  guardianPreference
} from './treatmentFunctions'
import PreferenceCard from './PreferenceCard';

const Preferences = ({ advanceReport }) => {
  return (
    <div className="preferences-cards container">
      <div className="flex flex-col w-full mx-auto sm:flex-row space-x-0 sm:space-x-8 md:space-x-20 space-y-6 sm:space-y-0 print-flex print-no-mb">
        <div className="flex-col w-full sm:flex-row sm:w-1/2 mb-5 print-no-mb print-pt">
          <div className="text-sw-dark-blue text-xs font-bold mb-5">
            ADOLESCENT
          </div>
          <PreferenceCard
            readinessData={childReadiness(advanceReport)}
            preferenceData={childPreference(advanceReport)}
          />
        </div>
        <div className="flex-col w-full sm:flex-row sm:w-1/2">
          <div className="text-sw-dark-blue text-xs font-bold mb-5">
            PARENT
          </div>
          <PreferenceCard
            readinessData={guardianReadiness(advanceReport)}
            preferenceData={guardianPreference(advanceReport)}
          />
        </div>
      </div>
    </div>
  )
}

export default Preferences;
