import React from 'react'

const SBIRT_MAP = [
  "High Risk Substance Use, Weekly or More Tobacco / Nicotine ",
  "High Risk Substance Use, Weekly or More Alcohol ",
  "High Risk Substance Use, Weekly or More Marijuana ",
  "High Risk Substance Use, Prescription Drugs ",
  "High Risk Substance Use, Illegal Drugs ",
  "High Risk Substance Use, Inhalants ",
  "High Risk Substance Use, Herbs or synthetic drugs"
]

const getPhq99Score = (report, key) => {
  try {
    const arr = report.questions[key]
    const str = arr.find((q) => q.question.startsWith('phq_9: 9')).answer
    return str.slice(str.length - 2, str.length - 1)
  } catch (error) {
    return ''
  }
}

export const phq9AlertChild = (advanceReport) => {
  const phq9ScoreChild = getPhq99Score(advanceReport, 'child')

  if (phq9ScoreChild && phq9ScoreChild !== '0') {
    return `Positive Suicidality, PHQ-9 Item 9 = ${phq9ScoreChild}`
  }
  return ''
}

export const phq9AlertGuardian = (advanceReport) => {
  const phq9ScoreGuardian = getPhq99Score(advanceReport, 'guardian')

  if (phq9ScoreGuardian && phq9ScoreGuardian !== '0') {
    return `Parent Reports Suicidality, PHQ-9 Item 9 = ${phq9ScoreGuardian}`
  }
  return ''
}

export const depressionAlert = (advanceReport) => {
  try {
    const score = advanceReport.results.child.phq9_score
    if (score < 20) return ''

    return `Severe Depression, PHQ-9 Total = ${score}`
  } catch (error) {
    return ''
  }
}

export const crafftAlert = (advanceReport) => {
  try {
    const score = advanceReport.results.child.crafft_score
    if (score < 2) return ''

    return `High Risk Substance Use, CRAFFT Total = ${score}`
  } catch (error) {
    return ''
  }
}

export const sbirtAlerts = (advanceReport) => {
  // Map over the possible outputs for each severe sbirt possibility
  // Each sbirt question starts with "sbirt: <number of question here>" so we just use index of the map

  // Less critical items (first 3 sbirt questions) only are severe if 'Weekly or more' is the answer
  // More critical items (#4-#7 sbirt questions) are severe if the answer is anything but 'Never'

  // We use a try catch because it's possible questions are skipped or whole surveys are not taken
  return SBIRT_MAP.map((item, i) => {
    try {
      const answer = advanceReport.questions.child.find((q) => q.question.startsWith(`sbirt: ${i+1}`)).answer_text
      if ((i <= 3 && answer !== 'Weekly or more') || (i > 3 && answer === 'Never')) {
        return null
      }

      return <div>{item}</div>
    } catch (error) {
      return ''
    }
  })
}

export const catSSAlert = (advanceReport) => {
  try {
    const score = advanceReport.results.child.cat
    if (!score || score === 'Low or no risk.') return ''

    return `Positive Suicidality, CAT-SS is ${score}`
  } catch (error) {
    return ''
  }
}

export const getCmrsAlert = (advanceReport, key) => {
  try {
    const score = advanceReport.resultsp[key].cmrs
    if (score !== 'Concern.') return ''
    
    if (key === 'child') {
      return 'Positive Concern for Mania'
    }

    return 'Parental Concern for Mania'
  } catch (error) {
    return ''
  }
}

export const gadAlert = (advanceReport) => {
  try {
    const score = advanceReport.results.child.gad_score
    if (score < 15) return ''

    return `Severe Anxiety, GAD-7 Total = ${score}`
  } catch (error) {
    return ''
  }
}
