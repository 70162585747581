import React from 'react';
import '../../assets/scss/_common.scss';

const DisclaimerComponent = ({ question, button }) => (
  <div>
    <div className="blurred-background" />
    <div className="overlay-container">
      <div className="overlay-question-view">
        <p className="question-text">
          {question}
        </p>
      </div>
      {button}
    </div>
  </div>
);

export default DisclaimerComponent;
