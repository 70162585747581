export const throttle = (func, wait, options = {}) => {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  var later = function () {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function () {
    var now = Date.now();
    if (!previous && options.leading === false) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
};

// Copied from https://medium.com/javascript-inside/safely-accessing-deeply-nested-values-in-javascript-99bf72a0855a
// and improved by adding a default value
// p = path to your value in the form of an array for each property ie ['user', 'post', 0, 'comments']
// o = object you're checking
// d = default value if the value is null/undefined/not found
export const get = (p, o, d = null) => {
  const v = p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
  if (v === null || v === undefined) return d;
  return v;
};

export const getId = () => {
  const findToken = window.location.search.indexOf('=');
  const token = window.location.search.slice(findToken + 1, window.location.search.length)
  return token
}
