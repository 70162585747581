import React from 'react';
import { phqNotes } from '../tableNotes'

const DepressionRow = ({ advanceReport }) => {
  const { child, guardian } = advanceReport.results

  const guardianAnswer = guardian.phq9 ? `PHQ-9 = ${guardian.phq9}` : 'N/A'

  const childAnswer = child.phq9 ? `PHQ-9 = ${child.phq9.replace(/\./g, '')}` : 'N/A'

  const childScore = () => {
    const { child } = advanceReport.questions
    const score = child.find((item) => item.question.startsWith('phq_9: 10'))

    if (!score) return null

    return (
      <p>
        {score.answer} <span>to has felt sad or depressed most days</span>
      </p>
    )
  }

  return (
    <div className="table-row" role="row">
      <div className="table-cell font-bold text-lg text-sw-dark-blue py-3" role="rowheader">
        Depression
      </div>
      <div className="table-cell text-xs text-sw-dark-blue font-bold py-3 pr-3 align-top leading-loose" role="gridcell">
        {childAnswer}
        {childScore()}
      </div>
      <div className="table-cell text-xs text-sw-dark-blue font-bold py-3 pr-3 align-top leading-loose" role="gridcell">
        {guardianAnswer}
      </div>
      <div className="table-cell align-top" role="gridcell">
        {phqNotes(advanceReport)}
      </div>
    </div>
  )
}

export default DepressionRow