import React from 'react';
import decideBold from './reportFunctions';

const DetailsQuestionRow = ({ question, answer, questionModule, isEven }) => {
  var rowColor = ""
  if (isEven) rowColor = "bg-sw-lightest-gray"

  return (
    <div className={`details-question-row table-row ${rowColor}`} role="row">
      <div className="table-cell text-xs text-sw-dark-blue pl-2 py-2 pr-5 w-5/6 sm:w-3/4 leading-normal" role="gridcell">
        <div>
          {question}
        </div>
      </div>
      <div className="table-cell text-xs text-sw-dark-blue py-2 w-1/6 sm:w-1/4 leading-normal" role="gridcell">
        {questionModule ? <div style={decideBold(questionModule, answer)}>{answer}</div> : null}
      </div>  
    </div>
  )
}

export default DetailsQuestionRow
