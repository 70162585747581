import React from 'react';
import '../../assets/scss/_common.scss';

const DefaultComponent = ({ title, question, button }) => (
  <div>
    <div className="blurred-background" />
    <div className="overlay-container">
      <div className="exit-view">
        <p className="exit-title">
          {title}
        </p>
      </div>
      <div style={{ flexDirection: 'column' }} className="overlay-question-view">
        <p className="overlay-header">
          Question Instructions:
        </p>
        <p className="overlay-description">
          {question}
        </p>
        <p className="overlay-header">
          How should I respond?
        </p>
        <p className="overlay-description">
          Consider the choices and answer as honestly as you can.
        </p>
        <p className="overlay-header">
          I still need help.
        </p>
        <p className="overlay-description">
          That's okay, we're here to help. Please speak to the person who gave you the link to this survey, and ask for assistance
        </p>
      </div>
      {button}
    </div>
  </div>
);

export default DefaultComponent;
